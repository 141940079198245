import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { Row, Col } from "antd";
import auth from "services/authService";
import AdminMenu from "components/admin/AdminMenu";

const currentUser = auth.getCurrentUser();

class AdminProtected extends Component {
  state = {};
  render() {
    const { path, component: Component, render, ...rest } = this.props;

    return (
      <Row>
        <Col span={6}>
          <AdminMenu />
        </Col>
        <Col span={17} offset={1}>
          <Route
            path={path}
            {...rest}
            render={props => {
              if (!currentUser || currentUser.type !== 1)
                return (
                  <Redirect
                    to={{
                      pathname: "/access-denied",
                      state: { from: props.location }
                    }}
                  />
                );
              return Component ? <Component {...props} /> : render(props);
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default AdminProtected;
