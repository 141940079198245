import React from "react";
import { Button, message } from "antd";
import { authoriseXero } from "../../../services/employerService";
import Form from "../../common/FormElements/Form";
import Joi from "joi-browser";

class XeroIntegration extends Form {
  state = {
    data: {
      clientId: "",
      clientSecret: ""
    },
    errors: {}
  };

  schema = {
    clientId: Joi.string()
      .min(1)
      .max(100)
      .label("Xero Client ID"),
    clientSecret: Joi.string()
      .min(4)
      .max(100)
      .label("Xero Client Secret")
  };

  iniateXeroIntegration = async () => {
    const { employerId } = this.props;
    const { data } = this.state;
    const { data: result } = await authoriseXero({ ...data, employerId });
    if (!result.success) return message.error("error");

    window.location.href = result.redirectURL;
  };

  render() {
    //We need initiate the connection, so when
    const { employerId } = this.props;
    return (
      <div>
        <p>Integrate Xero Payroll access</p>
        <p>
          Under your Xero Apps{" "}
          <a href="https://developer.xero.com/myapps">
            https://developer.xero.com/myapps
          </a>{" "}
          you can find Client ID and Secret
        </p>
        <p>
          Place this into redirect URI:{" "}
          <strong>
            {"https://25399184.ngrok.io/public/xero/callback/" + employerId}
          </strong>
        </p>
        {this.renderInput("clientId", "Xero Client ID")}
        {this.renderInput("clientSecret", "Xero Client Secret")}
        <Button onClick={this.props.cancel}>Cancel</Button>
        <Button onClick={this.iniateXeroIntegration}>Connect to Xero</Button>
      </div>
    );
  }
}

export default XeroIntegration;
