import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "services/authService";
const currentUser = auth.getCurrentUser();

class ProtectedRoute extends Component {
  state = {};
  render() {
    const { path, component: Component, render, ...rest } = this.props;

    return (
      <Route
        exact
        path={path}
        {...rest}
        render={props => {
          if (!currentUser)
            return (
              <Redirect
                to={{
                  pathname: "/employee/login",
                  state: { from: props.location }
                }}
              />
            );
          return Component ? <Component {...props} /> : render(props);
        }}
      />
    );
  }
}

export default ProtectedRoute;
