import http from "./httpService";

const apiEndpoint = "/employee";

export async function createEmployee(details) {
  return await http.post("/public" + apiEndpoint, details);
}

export async function confirmPhone(details) {
  return await http.post("/public" + apiEndpoint + "/confirm/phone", details);
}

export async function getEmployeeDetails() {
  return await http.get(apiEndpoint);
}

export async function getEmployeeViaId(employee) {
  return await http.post(apiEndpoint, employee);
}

export async function connectXeroPayroll() {
  return await http.get(apiEndpoint + "/xero/payroll/connect");
}

export async function addBeneficiary(beneficiary) {
  return await http.post(apiEndpoint + "/beneficiary", beneficiary);
}

export async function updateBeneficiary(beneficiary) {
  return await http.post(apiEndpoint + "/beneficiary/update", beneficiary);
}

export async function requestSalaryAdvance(details) {
  return await http.post(apiEndpoint + "/advance/request", details);
}

export async function confirmSalaryAdvance(details) {
  return await http.post(apiEndpoint + "/advance/confirm", details);
}

//all in one Employee earning details
export async function getCurrentEarningsDetails() {
  return await http.get(apiEndpoint + "/earnings/details");
}

export async function getTotalEarnedThisCycle() {
  return await http.get(apiEndpoint + "/total-earned");
}

export async function getThisCycleWithdrawals() {
  return await http.get(apiEndpoint + "/advances");
}

export async function getTransactionHistory() {
  return await http.get(apiEndpoint + "/advances/history");
}

export async function requestPasswordChange(details) {
  return await http.post(
    "/public" + apiEndpoint + "/password/change/request",
    details
  );
}

export async function updatePassword(details) {
  return await http.post(apiEndpoint + "/password/change", details);
}

export async function changePasswordPublicRoute(details) {
  return await http.post("/public" + apiEndpoint + "/password/change", details);
}
