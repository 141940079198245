import React, { Component } from "react";
import { Spin, Col, Input, Typography, Button, Layout } from "antd";
import auth from "services/authService";
const { Text } = Typography;
const { Content } = Layout;

class AdminLogin extends Component {
  state = {
    userData: { email: "", password: "" },
    error: "",
    loading: false,
    phoneConfirmed: true
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const userData = { ...this.state.userData };
    userData[input.name] = input.value;
    this.setState({ userData });
  };

  login = async () => {
    this.startLoading();
    try {
      const response = await auth.adminLogin(this.state.userData);
      this.stopLoading();
      if (response.success) {
        window.location = "/admin/employers";
      } else {
        this.setState({ error: "Wrong login details" });
      }
    } catch (err) {
      this.stopLoading();
      this.setState({ error: "Wrong login details" });
    }
  };

  render() {
    return (
      <Layout className="blueBackground">
        <Content className="container">
          <Spin spinning={this.state.loading}>
            <Col
              span={12}
              offset={6}
              className="shadow p-3 mt-5 mb-5 bg-white rounded"
            >
              <Col span={16} offset={4} className="mt-4 mb-5">
                <h3>Level Admin</h3>

                <Input
                  className="mt-4 mb-3"
                  placeholder="Login"
                  onChange={this.handleChange}
                  value={this.state.userData.email}
                  name="email"
                />
                <Input.Password
                  className="mb-3"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={this.state.userData.password}
                  name="password"
                />
                {this.state.error ? (
                  <Text type="danger">
                    {this.state.error}
                    <br />
                  </Text>
                ) : null}
                <div className="alignCenter">
                  <Button
                    className="wideBtn roundedBtn blueButton"
                    size="large"
                    onClick={this.login}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Col>
          </Spin>
        </Content>
      </Layout>
    );
  }
}

export default AdminLogin;
