import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "/auth/";
const tokenKey = "token";

http.setJWT(getJWT());

export async function login(userData) {
  const { data } = await http.post(apiEndpoint, userData);
  localStorage.setItem(tokenKey, data.token);
  return data;
}

export async function employeeLogin(userData) {
  const { data } = await http.post(apiEndpoint + "employee", userData);
  localStorage.setItem(tokenKey, data.token);
  return data;
}

export async function adminLogin(userData) {
  const { data } = await http.post(apiEndpoint + "admin", userData);
  localStorage.setItem(tokenKey, data.token);
  return data;
}

export async function logout() {
  localStorage.removeItem(tokenKey);
}

export function loginWithJWT(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const user = jwtDecode(jwt);
    return user;
  } catch (ex) {
    return null;
  }
}

export default {
  login,
  adminLogin,
  logout,
  loginWithJWT,
  getCurrentUser,
  getJWT
};
