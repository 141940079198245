import React, { Component } from "react";
import { Row, Col, Popover, Spin } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

class DisplayEarnings extends Component {
  state = {};
  render() {
    const { totalEarned, daysLeftUntilPaycheck } = this.props;
    return (
      //TODO, determin when to spin
      <Spin spinning={totalEarned === undefined}>
        <div className="earned-panel">
          <Row>
            <Col span={12}>
              <Row>
                <p> Earned wages so far</p>
              </Row>
              <Row>
                <h3>
                  £{totalEarned ? totalEarned : "0.00"}{" "}
                  <Popover
                    placement="right"
                    content={
                      <div className="popover">
                        We calculate an approximate value (after taxes) that may
                        slightly differ from the actual amount earned
                      </div>
                    }
                  >
                    <QuestionCircleOutlined
                      className="ml-2"
                      style={{ fontSize: "23px", verticalAlign: "middle" }}
                    />
                  </Popover>
                </h3>
              </Row>
            </Col>
            <Col span={12}>
              {" "}
              <Row>
                <p> Next paycheck in</p>
              </Row>
              <Row>
                <h3>{daysLeftUntilPaycheck} days</h3>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default DisplayEarnings;
