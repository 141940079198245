import React, { Component } from "react";

class AccessDenied extends Component {
  state = {};
  render() {
    return <div>Access denied</div>;
  }
}

export default AccessDenied;
