import React, { Component } from "react";
import { Modal } from "antd";

class AccountNotReadyModal extends Component {
  state = {};
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        className="popup-modal"
        visible={visible}
        onCancel={onCancel}
        onOk={onCancel}
        footer={null}
      >
        {" "}
        <div className="not-ready-modal-wrapper">
          <h4>
            Your account is being set up and should be ready shortly. Once it's
            ready to use you will be notified.
          </h4>
          <button className="liquid-primary" onClick={onCancel}>
            <p>Close</p>
          </button>
        </div>
      </Modal>
    );
  }
}

export default AccountNotReadyModal;
