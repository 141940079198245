import http from "./httpService";

const apiEndpoint = "/admin/";

export async function addEmployerAccount(details) {
  return await http.post("/employer", details);
}

export async function rbGetCustomer() {
  return await http.get(apiEndpoint + "rb-customer");
}

//all in one Employee earning details
export async function getEmployeeEarningDetails(employee) {
  return await http.post(apiEndpoint + "employee/earnings/details", employee);
}
