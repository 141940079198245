import React from "react";
import { addEmployerAccount } from "services/employerService";
import { Button, Spin, Col, message } from "antd";
import Form from "components/common/FormElements/Form";
import Joi from "joi-browser";

class CreateEmployer extends Form {
  state = {
    data: {
      name: "",
      contactEmail: "",
      contactName: "",
      contactPhone: "",
      address: "",
      type: 0,
      payDay: undefined,
      workCycleEnd: undefined
    },
    loading: false,
    errors: {}
  };

  schema = {
    name: Joi.string()
      .min(1)
      .max(60)
      .label("Company Name"),
    contactName: Joi.string()
      .min(1)
      .max(60)
      .label("Contact Name"),
    contactPhone: Joi.string()
      .min(1)
      .max(15)
      .label("Contact phone"),
    address: Joi.string()
      .min(1)
      .max(60)
      .label("Company address"),
    contactEmail: Joi.string()
      .email()
      .required()
      .label("Email"),
    type: Joi.number()
      .min(1)
      .max(2)
      .required()
      .label("Integration Type"),
    payDay: Joi.number()
      .min(1)
      .max(28)
      .required()
      .label("Monthly salary pay day"),
    workCycleEnd: Joi.number()
      .min(1)
      .max(28)
      .required()
      .label("End of the pay month")
  };

  submitEmployerDetails = async () => {
    this.startLoading();
    const details = this.state.data;
    const { data: response } = await addEmployerAccount(details);
    this.stopLoading();

    if (response.success) {
      message.success("Employer account created");
    } else {
      message.error("error");
    }
  };

  pickIntegration = type => {
    let company = this.state.company;
    if (type === "deputy") {
      company.integration.type = 1;
    } else {
      company.integration.type = 2;
    }
    this.setState({ company });
  };

  render() {
    return (
      <Col span={20}>
        <h3> Create Employer Record</h3>
        <Spin spinning={this.state.loading}>
          {this.renderInput("name", "Company Name")}
          {this.renderInput("contactEmail", "Contact Email")}
          {this.renderInput("contactName", "Contact Name")}
          {this.renderInput("contactPhone", "Contact Phone")}
          {this.renderInput("address", "Company Address")}{" "}
          {this.renderRadioButtons("type", "Integration Type", [
            { name: "Deputy", value: 1 },
            { name: "Xero", value: 2 }
          ])}
          {this.renderInput("payDay", "Monthly salary pay day")}{" "}
          {this.renderInput("workCycleEnd", "End of the pay month")}{" "}
          <Button onClick={this.submitEmployerDetails}>Submit</Button>
        </Spin>
      </Col>
    );
  }
}

export default CreateEmployer;
