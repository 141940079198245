import React, { Component } from "react";
import { Button } from "antd";
import { authoriseXero } from "../../../services/employerService";
import { toast } from "react-toastify";

class ConnectXero extends Component {
  state = {};
  handleClick = async () => {
    const { data: result } = await authoriseXero();
    if (!result.success) return toast.error("Error");

    window.location.href = result.redirectURL;
  };
  render() {
    return (
      <div>
        Connect to Xero Account
        <Button onClick={this.handleClick}>Connect</Button>
      </div>
    );
  }
}

export default ConnectXero;
