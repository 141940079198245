import React from "react";
import { Redirect } from "react-router-dom";
import Form from "components/common/FormElements/Form";
import Joi from "joi-browser";

import { getAllEmployers } from "services/employerService";
import { createEmployee } from "services/employeeService";

import { Typography, Spin } from "antd";

import TermsPrivacyCheckbox from "components/employee/RegisterAndLogin/TermsPrivacyCheckbox";
import InputSearch from "../../common/FormElements/InputSearch";

const { Text } = Typography;

class EmployeeSignup extends Form {
  state = {
    redirectToPhoneConfirm: false,
    termsChecked: false,
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      employer: "",
      password: "",
      confirmPassword: ""
    },
    employers: [],
    loading: false,
    errors: {},
    error: ""
  };

  schema = {
    firstName: Joi.string()
      .min(1)
      .max(60)
      .label("First name"),
    lastName: Joi.string()
      .min(1)
      .max(60)
      .label("Last name"),
    email: Joi.string()
      .email()
      .required()
      .label("Email"),
    phone: Joi.string()
      .min(1)
      .max(17)
      .required()
      .label("Phone number"),
    password: Joi.string()
      .min(6)
      .max(15)
      .label("Password"),
    confirmPassword: Joi.string()
      .min(6)
      .max(15)
      .label("Password confirm"),
    employer: Joi.string()
      .min(1)
      .max(33)
      .required()
      .label("Employer")
  };

  async componentDidMount() {
    const { data: result } = await getAllEmployers();
    const employerRecords = result.employers;
    const employers = employerRecords.map((emp, count) => {
      return { key: count, name: emp.name, id: emp.id };
    });
    this.setState({ employers });
  }

  validatePassword = () => {
    const { password, confirmPassword } = this.state.data;
    if (password.length < 6) {
      return "Password must be at least 6 characters long";
    } else if (password !== confirmPassword) {
      return "The passwords don't match";
    } else {
      return null;
    }
  };

  agreeTermsPrivacy = e => {
    const { termsChecked } = this.state;
    this.setState({ termsChecked: !termsChecked });
  };

  setError = error => {
    this.setState({ error });
  };

  submitEmployeeForm = async () => {
    this.startLoading();
    const errors = this.validate();
    errors
      ? this.setState({
          errors,
          error: "Please complete all the required fields"
        })
      : this.setState({ errors: {} });

    if (errors) return this.stopLoading();
    const passwordError = this.validatePassword();
    if (passwordError)
      return this.setState({ error: passwordError, loading: false });
    const { termsChecked } = this.state;
    if (!termsChecked)
      return this.setState({
        error: "You must agree to our Privacy and Terms & Conditions policies",
        loading: false
      });
    const employeeDetails = this.state.data;
    try {
      const { data: result } = await createEmployee(employeeDetails);
      this.stopLoading();

      if (!result.success) {
        this.setError(result.error);
        return;
      }

      // UNCOMMENT FOR THE EMPLOYEE SELF REGISTRATION COMPONENT
      // auth.loginWithJWT(result.token);
      this.setState({ redirectToPhoneConfirm: true });
      window.location = "/employee/phone/confirm";
    } catch (error) {
      console.log(error);
      this.setError("Catught error " + error);
      return this.stopLoading();
    }
  };

  selectItem = item => {
    console.log("Selected item", item);
    let selectedId = "";
    if (item) {
      selectedId = item.id;
    }
    const { data } = this.state;
    data.employer = selectedId;
    this.setState({ data });
  };

  displayError = () => {
    const { error } = this.state;
    if (error)
      return (
        <Text type="danger">
          {error}
          <br />
        </Text>
      );
    return null;
  };

  render() {
    const { loading, employers, data } = this.state;

    if (this.state.redirectToPhoneConfirm) {
      return (
        <Redirect
          to={{
            pathname: "/employee/phone/confirm",
            state: {
              userEmail: data.email,
              phoneNumber: data.phone
            }
          }}
        />
      );
    }
    return (
      <div className="brand-bg-light">
        <Spin spinning={loading}>
          <div className="signup-form">
            <div className="signup-wrapper">
              <h3>Sign Up</h3>
              {/* {this.renderRadioButtons("employer", "Employer", employers)} */}

              <InputSearch
                items={employers}
                selectItem={this.selectItem}
                error={this.state.errors["employer"]}
              />

              {this.renderInput("firstName", "First Name")}
              {this.renderInput("lastName", "Last Name")}
              {this.renderTooltippedInput(
                "email",
                "Email",
                <p>
                  <b>Important</b> You must provide the same email you have on
                  the records with your employer.
                </p>
              )}
              {this.renderInput("phone", "Phone Number")}
              {this.renderInput("password", "Password", null, "password")}
              {this.renderInput(
                "confirmPassword",
                "Confirm Password",
                null,
                "password"
              )}

              <TermsPrivacyCheckbox onCheck={this.agreeTermsPrivacy} />
              {this.displayError()}
              <button
                className="signup-form-button"
                onClick={this.submitEmployeeForm}
              >
                <div className="body">
                  {" "}
                  <p> Sign Up</p>
                </div>
              </button>
              <br />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default EmployeeSignup;
