import React, { Component } from "react";
import WithdrawalsTable from "components/employee/ControlPanel/WithdrawalsTable";
import {
  getTransactionHistory,
  getEmployeeDetails
} from "services/employeeService";
import { convertDateToNumber } from "services/dateService";
import { Button, Checkbox, Col, Row, Spin, Typography } from "antd";
const { Text } = Typography;

class TransactionHistory extends Component {
  state = {
    error: "",
    loading: false,
    advances: [],
    filters: [],
    cycleStart: null,
    cycleEnd: null,
    searchDate: new Date()
  };

  async componentDidMount() {
    this.startLoading();
    try {
      await this.getEmployeeDetails();
      this.setCurrentPayCycle(new Date());
      const { data: response } = await getTransactionHistory();
      if (!response.success) {
        this.stopLoading();

        this.setState({
          error: "Error getting transaction history, please try again."
        });
        return;
      }
      this.setState({ advances: response.advances });
      return this.stopLoading();
    } catch (error) {
      this.setState({
        error: "Error getting transaction history, please try again."
      });
      this.stopLoading();
    }
  }

  setCurrentPayCycle = date => {
    //TODO, check if it goes a year back when months it is 2nd of Jan
    const { payDay } = this.state.employee.employer;
    const day = date.getDate();
    let startMonth, endMonth;
    let startYear = date.getFullYear();
    if (day > payDay) {
      startMonth = date.getMonth();
      endMonth = date.getMonth() + 1;
    } else {
      startMonth = date.getMonth() - 1;
      if (startMonth < 0) {
        startYear = date.getFullYear() - 1;
      }
      endMonth = date.getMonth();
    }

    let cycleStart = new Date();
    let cycleEnd = new Date();
    cycleStart.setDate(payDay + 1);
    cycleStart.setMonth(startMonth);
    cycleStart.setFullYear(startYear);
    cycleEnd.setDate(payDay + 1);
    cycleEnd.setMonth(endMonth);
    cycleEnd.setFullYear(date.getFullYear());

    this.setState({ cycleEnd, cycleStart });
  };

  getEmployeeDetails = async () => {
    const { data: response } = await getEmployeeDetails();
    this.setState({ employee: response.employee });
  };
  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onChange = checkedValues => {
    this.setState({ filters: checkedValues });
  };

  compareDates = (date1, date2) => {
    return new Date(date1) > new Date(date2);
  };

  filterAdvances = () => {
    let { advances, filters, cycleEnd } = this.state;
    const cycleEndDateId = convertDateToNumber(cycleEnd);
    console.log("Cycle end ID", cycleEndDateId);
    advances = advances.filter(advance => advance.date_id === cycleEndDateId);

    if (filters.length === 0 || filters.length === 2) return advances;
    return advances.filter(
      advance => advance.transaction_status === filters[0]
    );
  };

  getCurrentCycleString = () => {
    let cycleString = "";
    let { cycleEnd, cycleStart } = this.state;

    if (!cycleEnd || !cycleStart) return cycleString;

    if (!cycleEnd || !cycleStart) return cycleString;
    cycleString += cycleStart.toDateString();
    cycleString += " - " + cycleEnd.toDateString();
    return cycleString;
  };

  changeMonth = change => {
    let { searchDate } = this.state;
    const currentMonth = searchDate.getMonth();
    searchDate.setMonth(currentMonth + change);
    this.setState({ searchDate });
    this.setCurrentPayCycle(searchDate);
  };

  render() {
    const { error, loading } = this.state;

    let advances = this.filterAdvances();

    return (
      <div className=" minWidth container ">
        <Col span={18} offset={3}>
          <Spin spinning={loading}>
            Entire transaction history {error && <Text>{error}</Text>}
            <Checkbox.Group style={{ width: "100%" }} onChange={this.onChange}>
              <Row>
                <Col span={8}>
                  <Checkbox value="approved">Approved Only</Checkbox>
                </Col>

                <Col span={8}>
                  <Checkbox value="confirming">Pending</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
            <React.Fragment>
              <h5>Advance withdrawals for: </h5>{" "}
              <Button onClick={() => this.changeMonth(-1)}>
                Previous Cycle
              </Button>
              {this.getCurrentCycleString()} pay cycle{" "}
              <Button onClick={() => this.changeMonth(1)}>Next Cycle</Button>
            </React.Fragment>
            <WithdrawalsTable withdrawals={advances} />
          </Spin>
        </Col>
      </div>
    );
  }
}

export default TransactionHistory;
