import React, { Component } from "react";
import { Modal, Row } from "antd";

class CongratsModal extends Component {
  state = {};

  render() {
    const { showCongratsModal, closeCongratsModal, withdrawal } = this.props;
    return (
      <Modal
        visible={showCongratsModal}
        onCancel={closeCongratsModal}
        className="popup-modal"
        footer={null}
      >
        <div className="congrats-modal-wrapper">
          <div className="congrats-modal-header">
            <h1>
              Congrats!
              <span role="img" aria-label="congrats emoji" className="ml-1">
                👏
              </span>
            </h1>
            <h3 className="darkGraySubtitle">Transfer has been submitted. </h3>
          </div>
          <div className="modal-body">
            <div className="modal-body-wrapper">
              <h4>
                £
                {parseFloat(withdrawal.amount).toFixed(2)
                  ? parseFloat(withdrawal.amount).toFixed(2)
                  : "No value"}
              </h4>
              <p>Will be deposited into your bank account shortly.</p>
            </div>
          </div>

          <Row>
            <button className="liquid-primary" onClick={closeCongratsModal}>
              <p>Close</p>
            </button>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default CongratsModal;
