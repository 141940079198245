import React from "react";
import Form from "components/common/FormElements/Form";
import { Row, Col, Button } from "antd";
import Joi from "joi-browser";
import { addDeputyAPI } from "services/employerService";

class DeputyIntegration extends Form {
  state = {
    data: {
      apiEndpoint: "",
      apiKey: ""
    },
    errors: {}
  };

  schema = {
    apiEndpoint: Joi.string()
      .min(1)
      .max(60)
      .label("Company Name"),
    apiKey: Joi.string()
      .min(32)
      .max(32)
      .label("API Key")
  };

  handleSubmit = async () => {
    const { data } = this.state;
    const { employerId } = this.props;
    const { data: result } = await addDeputyAPI({ ...data, employerId });
    return this.props.submit(result);
  };

  render() {
    return (
      <div>
        Input API details to connect to Timesheet Deputy
        {this.renderInput("apiEndpoint", "Deputy API Endpoint")}
        {this.renderInput("apiKey", "Deputy API Key")}
        <Row>
          {" "}
          <Col>
            <Button onClick={this.props.cancel}>Cancel</Button>
          </Col>
          <Col>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DeputyIntegration;
