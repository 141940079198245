import http from "./httpService";

const apiEndpoint = "/employer";

export async function addEmployerAccount(details) {
  return await http.post(apiEndpoint, details);
}

export async function addDeputyAPI(details) {
  return await http.post(apiEndpoint + "/deputy", details);
}

export async function getEmployerDetails(employerId) {
  return await http.get(apiEndpoint + "/" + employerId);
}

export async function getAllEmployers() {
  return await http.get("/public" + apiEndpoint + "s");
}

export async function getAllEmployees(employerId) {
  return await http.get(apiEndpoint + "/" + employerId + "/employees");
}

export async function authoriseXero(details) {
  return await http.post("/public" + apiEndpoint + "/xero/connect", details);
}

export async function xeroCallback() {
  return await http.get(apiEndpoint + "/xero/callback");
}
