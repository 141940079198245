import React from "react";
import { Col, Spin, Layout } from "antd";
import Joi from "joi-browser";

import { register } from "services/userService";
import auth from "services/authService";
import Form from "components/common/FormElements/Form";

const { Content } = Layout;

class Register extends Form {
  state = {
    data: {
      firstName: "",
      secondName: "",
      email: "",
      password: "",
      confirmPassword: "",
      type: 1
    },
    errors: [],
    error: "",
    loading: false
  };

  schema = {
    firstName: Joi.string()
      .min(1)
      .max(60)
      .label("First Name"),
    secondName: Joi.string()
      .min(2)
      .max(60)
      .label("Second Name"),
    email: Joi.string()
      .email()
      .required()
      .label("Email"),
    type: Joi.number()
      .required()
      .label("Password"),
    password: Joi.string()
      .min(5)
      .required()
      .label("Password"),
    confirmPassword: Joi.string()
      .min(5)
      .required()
      .label("Password Confirmation")
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  passwordsMatch = () => {
    if (this.state.password !== this.state.confirmPassword) {
      let errors = this.state.errors;
      errors["confirmPassword"] = "Passwords confirmation doesn't match";
      this.setState({ errors });
      return false;
    } else {
      return true;
    }
  };

  doSubmit = async () => {
    this.startLoading();
    if (!this.passwordsMatch()) {
      return;
    }
    let userDetails = this.state.data;
    const response = await register({
      firstName: userDetails.firstName,
      secondName: userDetails.secondName,
      email: userDetails.email,
      type: userDetails.type,
      password: userDetails.password
    });
    auth.loginWithJWT(response.headers["x-auth-token"]);
    window.location = "/admin";

    this.stopLoading();
  };

  render() {
    return (
      <div>
        <Layout className="blueBackground">
          <Content>
            <div className="container blueBackground minWidth">
              <div>
                <Col
                  span={14}
                  offset={5}
                  className="shadow p-3 mt-5 mb-5 bg-white rounded"
                >
                  <Col span={16} offset={4} className="mt-4 mb-5">
                    <Spin spinning={this.state.loading}>
                      <h3>Sign Up</h3>
                      <form onSubmit={this.handleSubmit}>
                        {this.renderInput("firstName", "First Name")}
                        {this.renderInput("secondName", "Second Name")}
                        {this.renderInput("email", "Email")}

                        {this.renderInput("password", "Password", "password")}
                        {this.renderInput(
                          "confirmPassword",
                          "Confirm Password",
                          "password"
                        )}

                        {this.renderButton("Register")}
                      </form>
                    </Spin>
                  </Col>
                </Col>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default Register;
