import React from "react";
import Form from "components/common/FormElements/Form";
import Joi from "joi-browser";
import { Spin } from "antd";

class AddBeneficiaryForm extends Form {
  state = {
    data: {
      name: "",
      sortCode: "",
      accountNumber: "",
      addressRefinement: "",
      houseNumber: "",
      addressStreet: "",
      city: "",
      region: "",
      postCode: "",
      countryISO: "GB"
    },
    errors: []
  };

  schema = {
    name: Joi.string()
      .min(2)
      .max(100)
      .label("Name")
      .required(),
    sortCode: Joi.string()
      .label("Sort code")
      .min(6)
      .max(6)
      .required(),
    accountNumber: Joi.string()
      .label("Account Number")
      .min(8)
      .max(8)
      .required(),
    addressRefinement: Joi.string().allow(""),
    houseNumber: Joi.string().label("House Number"),
    addressStreet: Joi.string().label("Address Street"),
    city: Joi.string().label("City"),
    region: Joi.string()
      .label("Region")
      .allow(""),
    postCode: Joi.string().label("Post Code"),
    countryISO: Joi.string().allow("")
  };

  doSubmit = async () => {
    const { data } = this.state;
    this.props.setBeneficiary(data);
  };

  handleCancel = () => {
    this.setState({ errors: [] });
    this.props.onCancel();
  };

  render() {
    const { loading } = this.props;
    return (
      <Spin spinning={loading}>
        <h4>Update bank account details</h4>
        {this.renderInput("name", "Holder's name")}
        {this.renderInput("sortCode", "Account Sort Code", 6)}
        {this.renderInput("accountNumber", "Account Number", 8)}
        <h5>Account holder's address</h5>
        {this.renderInput("addressRefinement", "Flat/Apartment number")}
        {this.renderInput("houseNumber", "House Number")}
        {this.renderInput("addressStreet", "Street Address")}
        {this.renderInput("city", "City")}
        {this.renderInput("region", "Region")}
        {this.renderInput("postCode", "Postal Code")}
        {this.renderButton("Save")}
      </Spin>
    );
  }
}

export default AddBeneficiaryForm;
