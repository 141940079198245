import React, { Component } from "react";
import InfoModal from "components/common/InfoModal";
import { privacy } from "info/privacy";
import { terms } from "info/terms";
import { Button } from "antd";

class TermsPrivacyCheckbox extends Component {
  state = { showTermsModal: false, showPrivacyModal: false };
  closePrivacyModal = () => {
    this.setState({ showPrivacyModal: false });
  };

  closeTermsModal = () => {
    this.setState({ showTermsModal: false });
  };

  showTermsModal = () => {
    this.setState({ showTermsModal: true });
  };

  showPrivacyModal = () => {
    this.setState({ showPrivacyModal: true });
  };
  render() {
    const { showPrivacyModal, showTermsModal } = this.state;
    return (
      <React.Fragment>
        <InfoModal
          key="TermsConditions"
          show={showTermsModal}
          content={terms}
          handleCancel={this.closeTermsModal}
        />
        <InfoModal
          key="Privacy"
          show={showPrivacyModal}
          content={privacy}
          handleCancel={this.closePrivacyModal}
        />
        <div className="checkbox-wrapper ">
          <input
            type="checkbox"
            className=" small-checkbox"
            onChange={this.props.onCheck}
          />
          <span className="smallprint-wrapper">
            <p>
              {" "}
              By proceeding further you agree with Liquidwage's{" "}
              <Button
                className="noBorder smallPrint-link"
                type="primary"
                onClick={this.showTermsModal}
                ghost
              >
                Terms & Conditions
              </Button>{" "}
              and{" "}
              <Button
                className="noBorder smallPrint-link"
                type="primary"
                ghost
                onClick={this.showPrivacyModal}
              >
                Privacy Policy
              </Button>
            </p>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsPrivacyCheckbox;
