import React, { Component } from "react";
import WithdawModal from "../Modals/WithdrawModal";
import WithdrawalsTable from "../WithdrawalsTable";
import { Col, message, Row, Spin } from "antd";
import CongratsModal from "../Modals/CongratsModal";
import ConfirmModal from "../Modals/ConfirmModal";
import SalaryAdvancesPanel from "./SalaryAdvancesPanel";
import AccountNotReadyModal from "../Modals/AccountNotReadyModal";
import ScreenAlerts from "../ScreenAlerts";
import {
  requestSalaryAdvance,
  confirmSalaryAdvance
} from "services/employeeService";
import { cancelPendingAdvance } from "services/salaryAdvanceService";
import { convertDate, convertEndCycle } from "services/dateService";

class MainScreen extends Component {
  state = {
    email: null,

    showAccNotReadyModal: false,
    showWithdrawModal: false,
    showCongratsModal: false,
    showConfirmScreen: false,
    modalLoading: false,
    withdrawal: { amount: "", error: "" },
    withdrawals: [],
    confirmationCode: "",
    withdrawalId: ""
  };

  handleAmountChange = ({ currentTarget: input }) => {
    const withdrawal = { ...this.state.withdrawal };
    //check that the value is numeric
    if (!/\d+/g.test(input.value) && input.value.length > 0) {
      return;
    }
    if (input.value.length === 0) {
      withdrawal.amount = "";
      return this.setState({ withdrawal });
    }
    withdrawal.amount = parseFloat(input.value);
    withdrawal.commission = parseFloat((0.02 * input.value).toFixed(2));
    this.setState({ withdrawal });
  };

  inputConfirmationCode = ({ currentTarget: input }) => {
    if (input.value.length > 6) return;
    this.setState({ confirmationCode: input.value });
  };

  showWithdrawModal = () => {
    this.setState({
      showWithdrawModal: true
    });
  };

  closeWithdrawModal = () => {
    const withdrawal = { ...this.state.withdrawal };
    withdrawal.amount = "";
    withdrawal.commission = 0;
    withdrawal.error = "";
    this.setState({
      showWithdrawModal: false,
      showConfirmScreen: false,
      modalLoading: false,
      withdrawal
    });
  };

  showCongratsModal = () => {
    this.setState({ showCongratsModal: true });
  };

  showConfirmModal = withdrawal => {
    this.setState({
      withdrawalId: withdrawal.id,
      withdrawal,
      showConfirmScreen: true
    });
  };

  hideConfirmModal = () => {
    const withdrawal = { ...this.state.withdrawal };
    withdrawal.amount = "";
    withdrawal.commission = 0;
    withdrawal.error = "";
    this.setState({ showConfirmScreen: false, withdrawal });
  };

  cancelAdvance = async () => {
    console.log("About to cancel the advance");
    const { withdrawalId } = this.state;
    const { data: response } = await cancelPendingAdvance({
      advance_id: withdrawalId
    });
    if (!response.success) {
      this.state({ shoConfirmModal: false });
      return message.error("Error, try again later");
    }
    console.log("need to update the table, via parent call");
    this.props.updatedAdvnacesList(response.advanceRecord);
    this.setState({
      showConfirmScreen: false,
      withdrawal: { amount: "", error: "" }
    });
    message.success("Request updated");
  };

  closeCongratsModal = () => {
    this.setState({
      showCongratsModal: false,
      withdrawal: { amount: 0, error: "" }
    });
  };
  showAccNotReadyModal = () => {
    this.setState({ showAccNotReadyModal: true });
  };
  closeAccNotReadyModal = () => {
    this.setState({ showAccNotReadyModal: false });
  };

  confirmWithdrawal = async () => {
    this.setState({ modalLoading: true });
    let { withdrawal } = this.state;
    const { confirmationCode, withdrawalId } = this.state;
    console.log("WITHDRAWAL", withdrawal);
    try {
      //TODO: add code confirmation step of the withdrawal
      const { data: response } = await confirmSalaryAdvance({
        confirmationCode: confirmationCode,
        advance_id: withdrawalId
      });
      if (response.success) {
        this.setState({
          modalLoading: false,
          showWithdrawModal: false,
          showConfirmScreen: false,
          showCongratsModal: true
        });
        console.log("Here is our response withdrawal", response.withdrawal);
        this.props.updatedAdvnacesList(response.withdrawal);
      } else {
        withdrawal.error = response.error;
        this.setState({
          modalLoading: false,
          withdrawal,
          confirmationCode: ""
        });
      }
    } catch (err) {
      withdrawal.error = "wrong confirmation code";
      this.setState({ modalLoading: false, withdrawal });
    }
  };

  requestSalaryAdvance = async () => {
    this.setState({ modalLoading: true });

    //Check if the account ready, if not present the modal
    const { accountReady } = this.props;
    if (!accountReady) {
      return this.setState({
        modalLoading: false,
        showWithdrawModal: false,
        showAccNotReadyModal: true
      });
    }

    let withdrawal = this.state.withdrawal;
    const amount = parseFloat(withdrawal.amount);
    const { totalAvailable } = this.props;

    if (amount > totalAvailable) {
      withdrawal.error = "Insufficient funds available";
      this.setState({ modalLoading: false });
      return;
    }

    if (amount < 1 || !amount) {
      withdrawal.error = "The amount needs to be more than £1.00";
      this.setState({ modalLoading: false });
      return;
    }

    try {
      const { data: response } = await requestSalaryAdvance({
        amount: withdrawal.amount,
        commission: withdrawal.commission
      });
      if (!response.success) {
        withdrawal.error = response.error;
        this.setState({ modalLoading: false, withdrawal });
      } else {
        withdrawal.error = "";
        this.setState({
          modalLoading: false,
          showWithdrawModal: false,
          showConfirmScreen: true,
          withdrawalId: response.advance_id,
          withdrawal
        });

        this.props.updatedAdvnacesList(response.advanceRecord);
        return;
      }
    } catch (err) {
      this.setState({
        modalLoading: false,
        withdrawal: { amount: 0, error: "Error trying to withdraw" }
      });
    }
  };

  render() {
    const {
      showWithdrawModal,
      modalLoading,
      withdrawal,
      showCongratsModal,
      showConfirmScreen,
      showAccNotReadyModal,
      confirmationCode
    } = this.state;

    const {
      cycleStart,
      cycleEnd,
      daysLeftUntilPaycheck,
      totalAvailable,
      totalWithdrawn,
      totalPending,
      totalEarned,
      beneficiaryId,
      allAdvances,
      accountReady,
      refreshDashboard,
      adminView,
      beneficiaryAccountDetails
    } = this.props;

    let periodStart = convertDate(cycleStart);
    let periodEnd = convertEndCycle(cycleEnd);
    return (
      <div className="  employee-dashboard">
        <ScreenAlerts
          beneficiary={beneficiaryId}
          accountReady={accountReady}
          refreshDashboard={refreshDashboard}
        />

        <Row className="dashboard-header">
          <Col span={21}>
            <h1>Current Pay Cycle</h1>
            <h5 style={{ fontWeight: 300 }}>
              <span className="mr-2" role="img" aria-label="calendar emoji">
                🗓️
              </span>
              {periodStart + "  "} - {"  " + periodEnd}
            </h5>
          </Col>
        </Row>
        <SalaryAdvancesPanel
          periodStart={periodStart}
          periodEnd={periodEnd}
          totalEarned={totalEarned}
          daysLeftUntilPaycheck={daysLeftUntilPaycheck}
          amountAvailable={totalAvailable}
          showWithdrawModal={this.showWithdrawModal}
          adminView={adminView}
        />

        <Spin key="withdrawalsTable" spinning={!this.state.withdrawals}>
          <WithdrawalsTable
            withdrawals={allAdvances}
            totalWithdrawn={totalWithdrawn}
            totalPending={totalPending}
            showConfirmModal={this.showConfirmModal}
            shoConfirmModal={this.showConfirmModal}
            updateAdvanceRecord={this.props.updatedAdvnacesList}
          />
        </Spin>

        <WithdawModal
          showWithdrawModal={showWithdrawModal}
          spinning={modalLoading}
          handleCancel={this.closeWithdrawModal}
          handleOk={this.requestSalaryAdvance}
          totalAvailable={totalAvailable}
          withdrawError={withdrawal.error}
          handleAmountChange={this.handleAmountChange}
          inputConfirmationCode={this.inputConfirmationCode}
          withdrawalValue={withdrawal.amount}
          showConfirmScreen={showConfirmScreen}
          confirmWithdrawal={this.confirmWithdrawal}
          error={withdrawal.error}
          beneficiaryAccountDetails={beneficiaryAccountDetails}
        />
        <ConfirmModal
          spinning={modalLoading}
          showConfirmModal={showConfirmScreen}
          hideConfirmModal={this.hideConfirmModal}
          inputConfirmationCode={this.inputConfirmationCode}
          confirmationCode={confirmationCode}
          confirmWithdrawal={this.confirmWithdrawal}
          cancelAdvance={this.cancelAdvance}
          error={withdrawal.error}
        />
        <CongratsModal
          closeCongratsModal={this.closeCongratsModal}
          showCongratsModal={showCongratsModal}
          withdrawal={withdrawal}
        />
        <AccountNotReadyModal
          visible={showAccNotReadyModal}
          onCancel={this.closeAccNotReadyModal}
        />
      </div>
    );
  }
}

export default MainScreen;
