import React, { Component } from "react";
import { Row, Col, Spin, Popover } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
class AvailableAdvancePanel extends Component {
  state = {};
  displayAmount = amount => {
    if (amount < 0) {
      return (0).toFixed(2);
    }
    return amount.toFixed(2);
  };
  render() {
    const { amountAvailable, adminView } = this.props;
    return (
      <Spin spinning={false}>
        <div className="withdraw-panel">
          <Row>
            <p> Withdraw up to</p>
          </Row>
          <Row>
            <Col span={12}>
              <h3>
                £{this.displayAmount(amountAvailable)}
                <Popover
                  placement="right"
                  content={
                    <div className="popover">
                      The maximum amount you can withdraw is 50% of your net
                      earnings.
                    </div>
                  }
                >
                  <QuestionCircleOutlined
                    className="ml-2"
                    style={{ fontSize: "23px", verticalAlign: "middle" }}
                  />
                </Popover>
              </h3>
            </Col>
            <Col span={2} />
            <Col span={10}>
              {!adminView && (
                <button onClick={this.props.showModal}>
                  <p>Withdraw</p>
                </button>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default AvailableAdvancePanel;
