import React, { Component } from "react";
import DeputyIntegration from "./DeputyIntegration";
import XeroIntegration from "./XeroIntegration";
import { Row, Button, message } from "antd";
import { getEmployerDetails, getAllEmployees } from "services/employerService";
import EmployeeTable from "./EmployeeTable";

class EmployerAccount extends Component {
  state = {
    employer: {
      rbGBPLedgerId: null,
      rbEnduserID: null,
      xero: { tokenSet: null }
    },
    employees: [],
    showDeputyForm: false,
    showXeroForm: false,
    loading: false
  };

  async componentDidMount() {
    const employerId = this.props.match.params.employer;
    const { data: result } = await getEmployerDetails(employerId);
    const { data: employeesResult } = await getAllEmployees(employerId);
    if (!result.success) return message.error("Error getting employee details");
    this.setState({
      employer: result.employer,
      employees: employeesResult.employees
    });
  }

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  toggleDeputyForm = () => {
    this.setState({ showDeputyForm: !this.state.showDeputyForm });
  };
  toggleXeroForm = () => {
    this.setState({ showXeroForm: !this.state.showXeroForm });
  };

  updateEmployer = async () => {
    const employerId = this.props.match.params.employer;
    const { data: result } = await getEmployerDetails(employerId);
    if (!result.success) return message.error("Failed to get employer details");
    this.setState({ employer: result.employer });
  };

  submitDeputyForm = result => {
    if (!result.success) return message.error("Failed to add deputy details");
    this.updateEmployer();
    this.toggleDeputyForm();
  };

  render() {
    const { employer, showDeputyForm, showXeroForm, employees } = this.state;
    return (
      <div>
        Employer Account
        {employer.rbEnduserID ? <p>Enduser Exist</p> : <p>No enduser found</p>}
        {employer.rbGBPLedgerId ? <p>Ledger Addeed</p> : <p>No ledger added</p>}
        <p>Complete Integrations</p>
        <Row>
          {" "}
          {!showDeputyForm && !employer.deputy && (
            <Button onClick={this.toggleDeputyForm}>
              Cnnect Deputy Timesheets{" "}
            </Button>
          )}
          {showDeputyForm && (
            <DeputyIntegration
              employerId={employer.id}
              cancel={this.toggleDeputyForm}
              submit={this.submitDeputyForm}
            />
          )}
        </Row>
        <Row className="mt-3">
          {" "}
          {!showXeroForm && !employer.xero && (
            <Button onClick={this.toggleXeroForm}>Connect Xero Payroll</Button>
          )}
          {showXeroForm && (
            <XeroIntegration
              employerId={employer.id}
              cancel={this.toggleXeroForm}
              submit={this.submitXeroForm}
            />
          )}
        </Row>{" "}
        <EmployeeTable employees={employees} />
      </div>
    );
  }
}

export default EmployerAccount;
