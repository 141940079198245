import React, { Component } from "react";
import { rbGetCustomer } from "../../services/adminService";
import { Spin, Row, Col } from "antd";

class AdminAccount extends Component {
  state = { rbCustomer: {}, rbLedger: "", loading: false };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  async componentDidMount() {
    this.startLoading();
    const { data: response } = await rbGetCustomer();
    this.setState({
      rbCustomer: response.body.customer,
      rbLedger: response.body.ledger
    });
    this.stopLoading();
  }
  render() {
    const { loading, rbCustomer, rbLedger } = this.state;
    return (
      <div>
        <Spin spinning={loading}>
          <Row>
            {" "}
            <Col>
              <b>Rails Bank Customer:</b>
            </Col>
            <Col> {rbCustomer.customer_id}</Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <b>Rails Bank Ledger:</b>
            </Col>{" "}
            <Col>{rbLedger.ledger_id}</Col>
          </Row>
          <Row>
            <b>Ledger balance:</b>£{rbLedger.amount}
          </Row>
        </Spin>
      </div>
    );
  }
}

export default AdminAccount;
