import React, { Component } from "react";
import AddBeneficiaryForm from "components/employee/AccountSettings/AddBeneficiaryForm";
import { Spin, message } from "antd";
import { addBeneficiary, getEmployeeDetails } from "services/employeeService";

class AccountSettings extends Component {
  state = { employee: {}, loading: false };

  async componentDidMount() {
    this.startLoading();
    try {
      await this.getEmployeeDetails();
      this.stopLoading();
    } catch (error) {
      message.error("Error");
      this.stopLoading();
    }
  }
  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };
  getEmployeeDetails = async () => {
    const { data: response } = await getEmployeeDetails();
    this.setState({ employee: response.employee });
  };

  openBeneficiaryForm = () => {
    this.setState({ showBeneficiaryForm: true });
  };

  hideBeneficiaryForm = () => {
    this.setState({ showBeneficiaryForm: false });
  };

  setBeneficiary = async beneficiary => {
    this.startLoading();

    const { enduser } = this.state.employee;
    const beneficiaryDetails = { ...beneficiary, enduser: enduser.enduser_id };
    try {
      const { data: response } = await addBeneficiary(beneficiaryDetails);
      if (!response.success) {
        return this.stopLoading();
      }
      this.setState({ beneficiaryDetails: response.beneficiary });
      this.hideBeneficiaryForm();
      this.stopLoading();
      return (window.location = "/");
    } catch (error) {
      return this.stopLoading();
    }
  };

  render() {
    const { loading, showBeneficiaryForm } = this.state;
    return (
      <Spin spinning={loading}>
        <div className="signup-form">
          <div className="signup-wrapper">
            {/* Connected Bank Account
            {employee.beneficiary && (
              <span className="mt-4">
                <p>Holder's name: {employee.beneficiary.person.name}</p>
                <p>Sort Code: {employee.beneficiary.uk_sort_code}</p>
                <p>Account Number:{employee.beneficiary.uk_account_number}</p>
              </span>
            )}
            <Button onClick={this.openBeneficiaryForm}>Change Account</Button> */}
            <AddBeneficiaryForm
              setBeneficiary={this.setBeneficiary}
              loading={loading}
              visible={showBeneficiaryForm}
              onCancel={this.hideBeneficiaryForm}
              onOk={this.setBeneficiary}
            />
          </div>
        </div>{" "}
      </Spin>
    );
  }
}

export default AccountSettings;
