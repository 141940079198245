import React, { Component } from "react";
import CreateEmployer from "./CreateEmployer/CreateEmployer";
import EmployersTable from "./CreateEmployer/EmployersTable";
import { Col, Row } from "antd";
import AdminAccount from "./AdminAccount";

class AdminDashboard extends Component {
  state = {
    activeBoard: "account"
  };

  renderActiveBoard = () => {
    const { activeBoard } = this.state;
    switch (activeBoard) {
      case "account":
        return <AdminAccount />;
      case "employers":
        return <EmployersTable />;
      default:
        return <CreateEmployer />;
    }
  };

  render() {
    return (
      <div className="container">
        Welcome to Level admin panel. <br />
        <Row>
          <Col span={17} offset={1}>
            {this.renderActiveBoard()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdminDashboard;
