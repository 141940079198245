import React, { useState, useEffect, useCallback } from "react";
import DropDownList from "components/common/FormElements/DropDownList";
import { Typography } from "antd";
const { Text } = Typography;
export default function InputSearch({ items, selectItem, error }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [found, setFound] = useState(false);

  const handleChange = event => {
    setFound(false);
    selectItem(null);
    setSearchTerm(event.target.value);
  };

  const onSelect = useCallback(
    item => {
      setSearchTerm(item.name);
      selectItem(item);
      setFound(true);
      setSearchResults([]);
    },
    [selectItem]
  );

  useEffect(() => {
    if (searchTerm.length < 1 || found) {
      setSearchResults([]);
      setNotFound(false);
      return;
    }
    const results = items.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setNotFound(results.length === 0);
    setSearchResults(results);
  }, [searchTerm, found, items]);

  return (
    <div>
      <input
        type="text"
        placeholder="Where do you work"
        value={searchTerm}
        onChange={handleChange}
      />
      {error && <Text type="danger"> {error}</Text>}

      <DropDownList
        items={searchResults}
        onSelect={onSelect}
        notFound={notFound}
      />
    </div>
  );
}
