import React, { Component } from "react";
import { getEmployeeViaId } from "../../../services/employeeService";
import { message } from "antd";
import ViewEmployeeDashboard from "./ViewEmployeeDashboard";
class ViewEmployee extends Component {
  state = { employee: { displayName: "" } };
  async componentDidMount() {
    const employeeId = this.props.match.params.employee;
    const { data: response } = await getEmployeeViaId({ id: employeeId });
    console.log("RESPONSE", response);
    if (!response.success) message.error("error");
    this.setState({ employee: response.employee });
  }
  render() {
    const { employee } = this.state;
    return (
      <div>
        Employee View
        <p>
          {employee.displayName}
          {employee._id}{" "}
        </p>
        <ViewEmployeeDashboard employee={this.props.match.params.employee} />
      </div>
    );
  }
}

export default ViewEmployee;
