import React, { Component } from "react";
import { Modal, Spin, Typography } from "antd";
const { Text } = Typography;

class ConfirmModal extends Component {
  state = {};
  render() {
    const {
      showConfirmModal,
      hideConfirmModal,
      inputConfirmationCode,
      confirmationCode,
      confirmWithdrawal,
      error,
      spinning
    } = this.props;

    return (
      <Modal
        className="popup-modal"
        visible={showConfirmModal}
        onCancel={hideConfirmModal}
        footer={null}
      >
        <Spin spinning={spinning}>
          <div className="confirm-modal-wrapper">
            <div className="confirm-modal-header alignCenter">
              <h4> Confirm transaction </h4>
            </div>
            <div className="alignCenter">
              {" "}
              <p>
                To verify your identity, we just sent you a code to your number
              </p>
            </div>

            <input
              className="mt-3 wideBtn"
              placeholder=". . . . . ."
              size="large"
              onChange={inputConfirmationCode}
              value={confirmationCode}
              name="confirmationCode"
            />

            {error ? (
              <div>
                <Text type="danger">
                  {error}
                  <br />
                </Text>
              </div>
            ) : null}
            <div className="modal-action-call">
              <button className="liquid-primary" onClick={confirmWithdrawal}>
                <p>Confirm</p>
              </button>
              <button
                className="text-button"
                onClick={this.props.cancelAdvance}
              >
                Cancel Request
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

export default ConfirmModal;
