import React, { Component } from "react";
import DisplayEarnings from "./DisplayEarnings";
import AvailableAdvancePanel from "./AvailableAdvancePanel";
import { Row } from "antd";

class SalaryAdvancesPanel extends Component {
  state = {};

  render() {
    const {
      totalEarned,
      daysLeftUntilPaycheck,
      amountAvailable,
      showWithdrawModal,
      adminView
    } = this.props;

    return (
      <React.Fragment>
        {" "}
        <Row className="dashboard-panel">
          <div>
            <DisplayEarnings
              totalEarned={totalEarned}
              daysLeftUntilPaycheck={daysLeftUntilPaycheck}
            />
          </div>
          <div>
            <AvailableAdvancePanel
              amountAvailable={amountAvailable}
              showModal={showWithdrawModal}
              adminView={adminView}
            />
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalaryAdvancesPanel;
