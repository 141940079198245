import React, { Component } from "react";
import { Menu } from "antd";

class AdminMenu extends Component {
  state = {};

  selectBoard = e => {
    const board = e.item.props.value;
    window.location = "/admin/" + board;
  };

  render() {
    return (
      <Menu onClick={this.selectBoard}>
        <Menu.Item key="account" value="account">
          Account
        </Menu.Item>
        <Menu.Item key="employers" value="employers">
          Employers
        </Menu.Item>
      </Menu>
    );
  }
}

export default AdminMenu;
