import React, { Component } from "react";
import Joi from "joi-browser";
import Select from "./Select";
import BasicInput from "components/common/FormElements/BasicInput";
import { Input, Radio, Tooltip } from "antd";

export default class Form extends Component {
  state = {
    data: {},
    errors: {},
    error: ""
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  setError = error => {
    this.setState({ error });
  };

  validate = () => {
    const options = {
      abortEarly: false
    };
    const result = Joi.validate(this.state.data, this.schema, options);

    const { error } = result;

    if (!error) return null;
    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ value, name }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleRadioButtonChange = ({ target: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    errors ? this.setState({ errors }) : this.setState({ errors: {} });

    if (errors) return;
    this.doSubmit();
  };

  renderButton = label => {
    return (
      <button onClick={this.handleSubmit} className="liquid-primary">
        <p> {label}</p>
      </button>
    );
  };

  renderInput = (name, label, maxchars = null, type = "text") => {
    const { data, errors } = this.state;
    return (
      <BasicInput
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        placeholder={label}
        error={errors[name]}
        type={type}
        className="m-1"
        maxchars={maxchars}
      />
    );
  };

  renderTooltippedInput = (name, label, tooltipBody) => {
    return (
      <Tooltip
        trigger={["focus"]}
        title={tooltipBody}
        placement="top"
        overlayClassName="numeric-input"
      >
        {this.renderInput(name, label)}
      </Tooltip>
    );
  };

  renderRadioButtons = (name, label, options) => {
    const { data } = this.state;
    return (
      <Radio.Group
        onChange={this.handleRadioButtonChange}
        label={label}
        value={data[name]}
        name={name}
      >
        {options.map((option, index) => (
          <div key={index}>
            <Radio value={option.value}>{option.name}</Radio>
          </div>
        ))}
      </Radio.Group>
    );
  };

  renderTextArea = (name, label, rows) => {
    const { TextArea } = Input;

    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <label>{label}</label>
        <TextArea
          className="form-control"
          autosize={{ minRows: rows, maxRows: 10 }}
          onChange={this.handleChange}
          error={errors[name]}
          value={data[name]}
          name={name}
          label={label}
        />
      </React.Fragment>
    );
  };

  renderSelect = (name, label, options) => {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        options={options}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  };
}
