import React from "react";
export let privacy = (
  <div className="info-modal-wrapper">
    <h1>Privacy Terms</h1>
    <h6>Who we are</h6>
    <p>
      Paragraph content. Paragraph content. Paragraph content. Paragraph
      content. Paragraph content. Paragraph content. Paragraph content.
      Paragraph content. Paragraph content.{" "}
    </p>
    <h6>How to contact us</h6>
    <p>You can contact us by emailing serhii@getlevel.uk </p>{" "}
    <h6>Personal Data we process</h6>
    <p>
      We apply the data minimisation principle and only collect the minimum
      necessary data for the process.
      <br />
      Personal Data we may collect about you from you, from your employer and
      from third parties about you, is outlined in the table below.
      <br />
      First name, surname, last name, username or similar identifier, title, and
      date of birth, company of employment, email address and telephone numbers,
      your bank sort code and account number, any details about transactions you
      made with us. Inferred or assumed information relating to your behaviour
      and interests, based on your online activity and/or in app. This is most
      often collated and grouped into “segments” (e.g., there may be a segment
      for users, living in London and aged under 25, who use our services twice
      a month). Technical data related to website visits usage, such as Internet
      Protocol (IP) address, mobile device identifier, your login data, browser
      type and version, time zone setting and location, browser plug-in types
      and versions, download errors, response time, page interaction (such as
      scrolling, clicks, and mouse-overs) device type and model, operating
      system and platform and other technology on the devices you use to access
      this Platform or use our services.{" "}
    </p>{" "}
    <h6>Aggregated Data</h6>
    <p>
      We may use and share personal data as “Aggregated Data” for statistical
      purposes only. Aggregated Data may be derived from your Personal Data, but
      once in aggregated form it will not constitute Personal Data for the
      purposes of the GDPR as this data does not directly or indirectly reveal
      your identity. However, if we combine or connect Aggregated Data with your
      Personal Data so that it can directly or indirectly identify you, we treat
      the combined data as Personal Data which will be used in accordance with
      this Privacy Policy.{" "}
    </p>{" "}
    <h6>No Special Categories of Personal Data</h6>
    <p>
      We do not collect any “Special Categories of Personal Data” about you
      (this includes details about your race or ethnicity, religious or
      philosophical beliefs, sex life, sexual orientation, political opinions,
      trade union membership, information about your health and genetic and
      biometric data). Nor do we collect any information about criminal
      convictions and offences.{" "}
    </p>{" "}
    <h6>How we use your Personal Data and why</h6>
    <p>
      We will only use your Personal Data where we have a lawful basis to do so.
      <br />
      We do not sell your personal information to any third parties. However, we
      may share your personal information with third parties as follows:
      <br />
      Selected other parties including business partners, suppliers and
      sub-contractors for the performance of any contract we enter into with
      your employer and you; analytics and search engine providers that assist
      us in the improvement and optimisation of our site and service; to comply
      with legal or regulatory obligations.{" "}
    </p>
    <h6>What happens when you do not provide necessary Personal Data?</h6>
    <p>
      Where we need to process your Personal Data either to comply with law, or
      to perform the terms of a contract we have with you and your employer and
      you fail to provide that data when requested, we may not be able to
      perform the contract we have or are trying to enter into with you (for
      example, to provide you with the functionalities of the Platform).
      <br />
      In this case, we may have to stop you using our service, but we will
      notify you if this is the case at the time.{" "}
    </p>{" "}
    <h6>Personal Data from Third Party Sources</h6>
    <p>
      In addition to the Personal Data that we collect directly from you (as
      described in the section immediately above this one), we may also collect
      certain of your Personal Data from third party sources, such as Identity
      Data, Contact Data, Financial Data, Transaction Data.{" "}
    </p>{" "}
    <h6>Who we share your Personal Data with</h6>
    <p>
      We share with your Employer financial Data and transaction Data Your
      employer(s) engaged with Level to provide Instant Wage Access to their
      employees. We share your personal data with your employer to provide them
      and you with such services.{" "}
    </p>{" "}
    <h6>How we keep your Personal Data secure</h6>
    <p>
      We have put in place appropriate security measures to prevent your
      Personal Data from being accidentally lost, used or accessed in an
      unauthorized way, altered or disclosed. Measures we take include:
      Firewalls; Data encryption; Intrusion detection systems; Background checks
      for all employees accessing our physical facilities. We have put in place
      procedures to deal with any actual or suspected Personal Data breach. In
      the event of any such breach, we have systems in place to work with
      applicable regulators. In addition, in certain circumstances (e.g. where
      we are legally required to do so) we may notify you of breaches affecting
      your Personal Data.
      <br />
      If you suspect any misuse or loss of, or unauthorised access to, your
      data, you should let us know immediately.{" "}
    </p>{" "}
    <h6>How long we store your Personal Data</h6>
    <p>
      We will only retain your Personal Data for so long as we reasonably need
      to use it for the purposes set out above in “How we use your Personal Data
      and why.”, unless a longer retention period is required by law (for
      example for regulatory purposes).
      <br />
      Generally, we will store your information for as long as you’re using
      Level, and for five years after that to comply with the law. In some
      circumstances, like cases of anti-money laundering or fraud, we may keep
      data longer if we need to and/or the law says we need to.
      <br />
      Information related with website visits and marketing activities are
      typically stored for three years.{" "}
    </p>{" "}
    <h6>Complaints</h6>
    <p>
      If you would like to make a complaint regarding this Privacy Policy or our
      practices in relation to your Personal Data, please contact us at:
      serhii@getlevel.uk.
      <br />
      We aim to respond with 24 hours to all complaints and resolve them within
      the shortest time possible. Under the regulatory framework we are required
      to send a final response within 15 business days. If you submit a
      complaint, we both must first attempt to resolve the issue directly
      between us. However, if you feel that your complaint has not been
      adequately resolved, please note that the GDPR gives you the right to
      contact our lead supervisory data protection supervisory authority, which
      for the UK and Ireland is the Information Commissioner’s Office.{" "}
    </p>{" "}
  </div>
);
