import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Spin, Button, Tag } from "antd";
import { getAllEmployers } from "../../../services/employerService";
class EmployerTable extends Component {
  state = { loading: false, employers: [] };

  async componentDidMount() {
    this.startLoading();
    const { data: response } = await getAllEmployers();
    if (response.success) this.setState({ employers: response.employers });
    this.stopLoading();
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  renderEmployers = () => {
    const { employers } = this.state;
    return employers.map((emp, count) => {
      return (
        <Row key={count}>
          <Col span={2}>
            <h4>
              {" "}
              <Link to={"/admin/employer/" + emp.id}>
                <Row>{emp.name}</Row>
              </Link>
            </h4>
          </Col>
          <Col span={3}>
            {emp.rbEnduserID ? (
              <Tag color="green">Enduser</Tag>
            ) : (
              <Tag color="red">No Enduser</Tag>
            )}
          </Col>
          <Col span={3}>
            {emp.rbGBPLedgerId ? (
              <Tag color="green">Ledger</Tag>
            ) : (
              <Tag color="red">No Ledger</Tag>
            )}
          </Col>
          <Col span={3}>
            {emp.deputy ? (
              <Tag color="green">Deputy On</Tag>
            ) : (
              <Tag color="red">No Deputy</Tag>
            )}
          </Col>
          <Col span={3}>
            {emp.xero ? (
              <Tag color="green">Xero On</Tag>
            ) : (
              <Tag color="red">Xero Off</Tag>
            )}
          </Col>
        </Row>
      );
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Spin spinning={loading}>
        <Row>
          <h3> Employers</h3>

          <Link className="ml-3 mt-1" to="/admin/employer/create">
            <Button>Create new</Button>
          </Link>
        </Row>
        <div className="mt-3">{this.renderEmployers()}</div>
      </Spin>
    );
  }
}

export default EmployerTable;
