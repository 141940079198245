import React, { Component } from "react";
import { Spin, message } from "antd";
import MainScreen from "components/employee/ControlPanel/Dashboard/MainScreen";

import {
  getCurrentEarningsDetails,
  getEmployeeDetails
} from "services/employeeService";

class EmployeeDashboard extends Component {
  state = {
    employee: { ledger: null, beneficiary: {}, enduser: {} },
    beneficiaryAccountDetails: {
      uk_account_number: "",
      uk_sort_code: "",
      holdersName: ""
    },
    totalEarned: 0,
    totalAvailable: 0,
    totalAdvanced: 0,
    totalPending: 0,
    totalInProcessing: 0,
    advances: [],
    allAdvances: [],
    cycleStart: null,
    cycleEnd: null,
    daysLeftUntilPaycheck: 0,
    approvedAdvances: [],
    enduser: {},
    updatingBeneficiary: false,
    loading: false
  };

  async componentDidMount() {
    this.startLoading();
    try {
      await this.getEmployeeDetails();
      await this.getTotalEarnedAndAvailable();
      this.stopLoading();
    } catch (error) {
      this.stopLoading();
    }
  }

  getTotalEarnedAndAvailable = async () => {
    const { data: response } = await getCurrentEarningsDetails();
    console.log("Current earning details", response);
    if (!response.success)
      return message.error("Error, getting earning details");
    const {
      totalEarned,
      totalAvailable,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      records,
      cycleStart,
      cycleEnd,
      daysLeftUntilPaycheck
    } = response;
    this.setState({
      totalEarned,
      totalAvailable,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      allAdvances: records,
      cycleStart,
      cycleEnd,
      daysLeftUntilPaycheck
    });
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  getEmployeeDetails = async () => {
    const { data: response } = await getEmployeeDetails();
    const {
      uk_sort_code: sortCode,
      uk_account_number: accNumber
    } = response.employee.beneficiary;

    const {
      name: holdersName
    } = response.employee.beneficiary.beneficiary_holder.person;
    const beneficiaryAccountDetails = { sortCode, accNumber, holdersName };
    this.setState({ employee: response.employee, beneficiaryAccountDetails });
  };

  updateAdvanceRecords = advanceRecord => {
    console.log("ADvance record", advanceRecord);
    let updatedAdvances = this.state.allAdvances.filter(
      record => record.id !== advanceRecord.id
    );

    updatedAdvances = [...updatedAdvances, advanceRecord];
    console.log("After adding", updatedAdvances);
    this.setState({ allAdvances: updatedAdvances });
  };

  ledgerReady = () => {
    const { ledger } = this.state.employee;

    if (!ledger) return false;
    return ledger.ledger_status === "ledger-status-ok";
  };

  checkEmployeeAcountReady = () => {
    const { isLedgerReady, payrollConnected } = this.state.employee;

    return isLedgerReady && payrollConnected;
  };

  refreshDashboard = () => {
    window.location.reload(false);
  };

  render() {
    const {
      loading,
      totalEarned,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      totalAvailable,
      allAdvances,
      approvedAdvances,
      cycleStart,
      cycleEnd,
      daysLeftUntilPaycheck,
      beneficiaryAccountDetails
    } = this.state;
    const { rbBeneficiaryId, employeeID, beneficiary } = this.state.employee;

    return (
      <Spin spinning={loading}>
        <MainScreen
          beneficiaryId={rbBeneficiaryId}
          beneficiary={beneficiary}
          employeeID={employeeID}
          accountReady={this.checkEmployeeAcountReady()}
          refreshDashboard={this.refreshDashboard}
          totalEarned={totalEarned}
          totalAvailable={totalAvailable}
          totalWithdrawn={totalAdvanced}
          totalPending={totalPending}
          totalInProcessing={totalInProcessing}
          allAdvances={allAdvances}
          approvedAdvances={approvedAdvances}
          updatedAdvnacesList={this.updateAdvanceRecords}
          cycleStart={cycleStart}
          cycleEnd={cycleEnd}
          daysLeftUntilPaycheck={daysLeftUntilPaycheck}
          beneficiaryAccountDetails={beneficiaryAccountDetails}
        />
      </Spin>
    );
  }
}

export default EmployeeDashboard;
