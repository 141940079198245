import React, { Component } from "react";
import { Row, Table } from "antd";
import { convertDate } from "services/dateService";
const { Column } = Table;

class WithdrawalsTable extends Component {
  state = {};

  getStatusTag = record => {
    const { showConfirmModal } = this.props;
    let statusTag;
    switch (record.transaction_status) {
      case "approved":
        statusTag = <p>complete</p>;
        break;
      case "processing":
        statusTag = <p>processing</p>;
        break;
      case "confirming":
        statusTag = (
          <Row key={record.id}>
            {" "}
            <button
              className="liquid-primary"
              onClick={() => showConfirmModal(record)}
            >
              <p>confirm</p>
            </button>
          </Row>
        );
        break;
      default:
        statusTag = <p>{record.transaction_status}</p>;
    }

    return statusTag;
  };

  render() {
    const { totalWithdrawn } = this.props;
    let withdrawals = [];
    if (this.props.withdrawals) {
      withdrawals = this.props.withdrawals.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }

    return (
      <div className="withdrawal-table">
        <React.Fragment>
          {totalWithdrawn !== undefined && (
            <React.Fragment>
              <span>
                <h1>Transaction History </h1>
              </span>
            </React.Fragment>
          )}
        </React.Fragment>
        {/* {totalWithdrawn !== undefined && (
          <Row>
            {" "}
            <p>
              Total withdrawn this pay cycle(including commission): £
              {totalWithdrawn.toFixed(2)}
            </p>
            <p>Total pending: £{totalPending}</p>
          </Row>
        )} */}
        <Table
          dataSource={withdrawals}
          showHeader={true}
          pagination={false}
          rowKey="id"
          className="table-header"
          rowClassName={(record, index) => {
            return "withdrawal-table-row";
          }}
          locale={{ emptyText: "No withdrawals this period" }}
        >
          <Column
            title="Date"
            dataIndex="createdAt"
            key="createdAt"
            rowKey="id"
            render={record => <span>{convertDate(record)}</span>}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            rowKey="id"
            width="20%"
            render={total => (
              <span>
                <p>
                  <b>{total ? "£" + total.toFixed(2) : "n/a"}</b>
                </p>
              </span>
            )}
          />
          <Column
            title="Fee"
            dataIndex="commission"
            rowKey="id"
            width="20%"
            render={total => (
              <span>
                <p>
                  <b>{total ? "£ " + total.toFixed(2) : "n/a"}</b>
                </p>
              </span>
            )}
          />
          <Column
            title="Status"
            key="action"
            rowKey="id"
            width="20%"
            render={record => <span>{this.getStatusTag(record)}</span>}
          />
        </Table>
      </div>
    );
  }
}

export default WithdrawalsTable;
