import React from "react";
export let terms = (
  <div className="info-modal-wrapper">
    <h1>Terms & Conditions</h1>
    <p>
      These terms of service (“Terms of Service”) constitute a legal agreement
      between you and Level Finance Ltd, incorporated and registered in England
      and Wales with company number 12161967 whose registered address is at 51
      Tudor road, London, E97SN.
      <br />
      (“Level”). As used in these Terms of Service, the words “you” and “your”
      refer to you, the user of Level’s Platform, as the party agreeing to these
      Terms of Service. The words “we”, “us”, “our” and any other variation
      thereof refer to Level.
      <br />
      You are currently subject to a contract of employment or a service
      agreement with your employer. The details of your employer will be set out
      on the Platform. Your employer has entered into a contract with us to
      facilitate paying employees who sign up to our services on their earned
      wages earlier than payroll date in accordance with the terms set out
      herein – in this respect we will be the employer’s agent.{" "}
    </p>
    <h6>These Terms of Service govern: </h6>
    <p>
      (a) your use of all websites, devices or mobile applications that we
      operate, including any pages within each such website, device, or
      application, any equivalent, mirror, replacement, substitute or backup
      website, device, or application, and pages that are associated with each
      such website, device, or application (hereinafter referred to as the
      “Platform”); and (b) the services we provide through the Platform
      including your ability to receive your salary from your employer earlier
      than you otherwise would be able to. The use of the word “including” in
      these Terms of Service to refer to specific examples will be construed to
      mean “including, without limitation” or “including but not limited to” and
      will not be construed to mean that the examples given are an exclusive
      list of the topics covered.
      <br />
      What do these Terms of Service cover? These Terms of Service are the terms
      and conditions that govern your access to, and use of, the Platform.
      <br />
      Why should you read these Terms of Service? These Terms of Service create
      a legally binding agreement between you and us. By accessing or using the
      Platform, you are accepting these Terms of Service.
      <br />
      You confirm that you can enter the agreement. You confirm and promise to
      us that you have the right, authority, and capacity to enter into these
      Terms of Service.{" "}
    </p>{" "}
    <h6>Acceptance of Terms</h6>
    <p>
      PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING THE
      PLATFORM, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE. IF YOU DO NOT
      WISH TO BE BOUND BY THESE TERMS OF SERVICE, YOU MAY NOT ACCESS OR USE THE
      PLATFORM. You may not use the Platform, or accept these Terms of Service,
      if (a) you are not of legal age to form a binding contract with Level; (b)
      you are prohibited by law from using, or receiving the services provided
      by, the Platform; or (c) you do not work in England.
      <br />
      Level may modify this Terms of Service from time to time. Any and all
      changes to these Terms of Service will be posted on the getlevel.uk. The
      Terms of Service will always indicate the date it was last revised. In
      addition, we will send you an email informing you of any changes in the
      Terms of Service prior to posting those changes. When you use the Platform
      after those changes are posted, you are deemed to have accepted the new
      Terms of Service and agree to be bound by any changes to the Terms of
      Service.{" "}
    </p>{" "}
    <h6>Registration Information & Accounts</h6>
    <p>
      In this section and these Terms of Service, “Account” means: (a) the
      secure online area of the Platform which contains information about you;
      and (b) your relationship with us. For the avoidance of doubt, your
      Account is not a bank account or an electronic money account or any other
      sort of payment account. In order to use the Platform and activate your
      Account, you must provide certain information about yourself as prompted
      by the Account verification code you will access through the message sent
      to your phone by Level. You agree and understand that you are responsible
      for maintaining the confidentiality of the combination of your login ID
      and password, which together allow you to access the Platform. That login
      ID and password, together with any profile information (including, as
      applicable, your name, mobile number or other contact information) and
      Bank Account Information, form your “Registration Information.”
      <br />
      If you believe that your Registration Information or a device that you use
      to access the Platform has been lost or stolen, that someone has gained
      access to your Account without your permission, or that an unauthorised
      transaction has occurred, you must notify us IMMEDIATELY at
      serhii@getlevel.uk.
      <br />
      You can de-activate your Account at any time, for any reason, by sending
      an email to serhii@getlevel.uk. At this point, we should remind you that
      we also have certain rights to suspend or terminate your Account.{" "}
    </p>
    <h6>Privacy and your personal data</h6>
    <p>
      For information about Level’s data protection practices, please read
      Level’s privacy policy as updated from time to time, which is hereby
      incorporated into this Terms of Service. This policy explains how Level
      treats your personal data when you use the Platform.
    </p>
    <h6>Contact Details</h6>
    <p>
      You can contact us by writing to us at serhii@getlevel.uk. If we have to
      contact you we may do so by telephone or by email to the address provided
      by you or your employer.
      <br />
      When we use the words “writing” or “written” in these Terms of Service,
      this includes emails. For contractual purposes, you: (a) consent to
      receiving communications from Serhii by email; and (b) agree that all
      communications that we provide to you by email satisfy any legal
      requirement that such communications would satisfy if it were provided in
      hardcopy writing by post.{" "}
    </p>{" "}
    <h6>Accuracy of and changes to your information</h6>
    <p>
      You agree to promptly let your employer know of any update to your
      Registration Information whenever the information provided to us is no
      longer accurate. If you need help in changing your information, please
      email us at serhii@getlevel.uk. We are not responsible for any payment
      processing errors or Fees or other Platform-related issues arising from
      your failure to keep your employer updated so that your Registration
      Information is current.{" "}
    </p>{" "}
    <h6>Early Salary Payment services</h6>
    <p>
      In this section, Level is acting as agent for and on behalf of your
      employer.
      <br />
      In this section and these Terms of Service:
      <br />
      (a) “Early Salary Payment” means, following the acceptance of your
      Request, the payment of the amount of accrued but unpaid Salary set out in
      your Request, earlier than the relevant Salary Day to your bank account;
      (b) “Payment Cycle” means the period time which the Salary you are paid,
      pays you for – in most cases the Payment Cycle is monthly; (c) “Request”
      means a request made via the Platform to your employer to be paid a
      proportion of your accrued but unpaid Salary earlier than the relevant
      Salary Day to your bank account; (d) “Salary” means the payment made by
      your employer to you for work carried out during that Payment Cycle, as
      its employee; and (e) “Salary Day” means the day upon which the full
      amount of your Salary for that Payment Cycle would be paid to you, but for
      you submitting Requests;
      <br />
      Through the Platform you are able to send Requests.
      <br />
      Your employer in its sole discretion will decide whether it wants to
      accept each Request. If the Request is accepted, your employer will send a
      payment order to us to execute the corresponding Early Salary Payment.
      Upon our acceptance of your employer’s payment order, the Early Salary
      Payment will be executed by us. Level is under no obligation to accept
      your employer’s payment order, especially in the event that your employer
      does not hold enough money in its electronic money account with us to
      execute the payment order. We have no liability to you for any losses you
      incur as a result of us not accepting a payment order from your employer.
      <br />
      We can terminate your right to make Requests and access the Platform and
      the associated information at any time and for any reason, including upon
      your employer’s request.
      <br />
      If you have received any Early Salary Payments prior to the Salary Day of
      a particular a Payment Cycle, on the Salary Day for that Payment Cycle you
      will receive your “Net Salary” which means your Salary for that Payment
      Cycle less:
      <br />
      (a) the total of Early Salary Payments executed in that Payment Cycle; and
      (b) the Fees accrued in that Payment Cycle (please see section (Fees) for
      more information).
      <br />
      In the event that the total of the Early Salary Payments executed and the
      Fees accrued in your Payment Cycle are more than your Salary for that
      Payment Cycle:
      <br />
      (a) your Net Salary for that Payment Cycle will be zero; and (b) the
      amount of the payment to you on your next Salary Day will be reduced
      accordingly. Please see Appendix for a working example.
      <br />
      Typically, Early Salary Payments will be executed within four hours of
      each Request, failing which Level will not charge you (or reimburse you,
      as applicable) any Fees in relation to the affected Early Salary Payments.
      <br />
      We, or your employer, may limit the total number of Requests you can make
      at any given time or over a period of time. We may refuse to permit Early
      Salary Payments, if we reasonably believe such refusal is necessary or
      advisable for legal or security reasons or to protect the Platform or
      Level.{" "}
    </p>{" "}
    <h6>Fees</h6>
    <p>
      In this section and these Terms of Service “Fees” means the fixed sum in
      pound sterling applicable to each Early Salary Payment, as agreed by your
      employer with Level from time to time.
      <br />
      In order to process Early Salary Payments, we need to carry out work
      including executing each Early Salary Payment and adjusting your
      employer’s records to pay you less money on the Salary Day. Making
      provision for the execution of Early Salary Payments is over and above
      what the employer is required to do in accordance with your employment
      contract. Accordingly, you are required to pay a Fee for each Early Salary
      Payment. By agreeing to these Terms of Service and making Requests you
      agree to pay such Fees. You agree to allow your employer to pay those fees
      on your behalf deducted out of your salary.
      <br />
      Fee for Early Salary Payments 2% of the Early Salary Payment value. If you
      have withdrawn a total of 100 pounds sterling during this Payment Cycle,
      total fee is going to amount to 2 pounds sterling.
      <br />
      The Fees shall accumulate during each Payment Cycle and shall become due
      and owing to us on the relevant Salary Day.
      <br />
      Other than as set out in these Terms of Service, there are no set-up or
      enrolment fees, no monthly or recurring costs for our services and no
      termination fees.{" "}
    </p>{" "}
    <h6>Your Use of the Platform</h6>
    <p>
      Your right to access and use the Platform and related services is personal
      to you and is not transferable by you to any other person or entity, and
      you may only access and use the Platform for lawful purposes.
      <br />
      You warrant that the Early Salary Payments are just and due and that you
      have not received payment for the Early Salary Payments or any part of the
      Early Salary Payments. You further warrant that if you receive payment for
      the relevant Early Salary Payments, including by your employer, you will
      transfer those payments, or permit those payments to be transferred to
      Level.
      <br />
      Accurate records enable us to provide our services to you. You must
      provide your employer (or Level, if applicable) with true, accurate,
      current, and complete information about your accounts maintained at third
      party bank accounts or other sites (“Bank Account Information”), and you
      may not misrepresent any Bank Account Information. In order for the
      Platform to function effectively, you must also ensure your employer has
      Bank Account Information that is up to date and accurate. If you do not do
      this, the accuracy and effectiveness of the services provided to you
      pursuant to these Terms of Service will be affected. You represent that
      you are a legal owner of, and that you are authorised to provide your
      employer with, all Bank Account Information and other information
      necessary to facilitate your use of the Platform according to these Terms
      of Service.
      <br />
      Your access and use of the Platform may be interrupted from time to time
      for any of several reasons, including the malfunction of equipment,
      periodic updating, maintenance or repair of the Platform, or other actions
      that Level, in its sole discretion, may elect to take. You acknowledge and
      agree that Level will have no obligation to provide you with any support
      or maintenance in connection with the Platform.{" "}
    </p>{" "}
    <h6>Modification to Platform or Related Services</h6>
    <p>
      Level reserves the right at any time and from time to time to modify or
      discontinue, temporarily or permanently, the Platform (in whole or in
      part) with or without notice.
      <br />
      We reserve the right to change the services related to the provision of
      Early Salary Payments, including applicable Fees, in our sole discretion
      and from time to time. In such event, if you are a member to such
      services, we will provide notice to you. If you do not agree to the
      changes after receiving a notice of the change to the Early Salary Payment
      services, you may stop using such services. Your use of such Early Salary
      Payment services or of your Account after you are notified of any
      change(s) will constitute your agreement to such change(s).
      <br />
      You agree that Level shall not be liable to you or to any third party for
      any modification, suspensions, or discontinuance of the Platform or Early
      Salary Payment services (or any part thereof).{" "}
    </p>{" "}
    <h6>Rights You Grant to Us</h6>
    <p>
      By submitting information, data, passwords, usernames, other log-in
      information, materials and other content to Level through the Platform
      (including through your employer) or other related services provided by
      Level, you are licensing that content to Level solely for the purpose of
      providing the Platform and related services. Level may use and store the
      content for the purpose of providing the Platform and related services to
      you. By submitting this content to Level, you represent that you are
      entitled to submit it to Level for use for this purpose, without any
      obligation by Level to pay any fees or other limitations.
      <br />
      By activating your Account, you consent to your employer sharing
      employment data required for us to perform our obligations under these
      Terms of Service and the service you have requested, including providing
      you with Early Salary Payments. This includes but is not limited to (i)
      name, (ii) employee identification number, (iii) contact information
      (email and/or mobile phone number), (iv) gross and net pay, (v) bank
      account and routing number for purposes of payment remittance. For
      information about how Level treats your personal data when you use the
      Platform, please read Level’s privacy policy (available at
      www.getlevel.uk/privacy).
    </p>{" "}
    <h6>No Unlawful or Prohibited Use</h6>
    <p>
      As a condition of your use of the Platform, you represent and warrant to
      Level that you will not use the Platform for any purpose that is unlawful
      or prohibited by these Terms of Service.
      <br />
      You agree that you will not:
      <br />
      a) license, sell, rent, lease, transfer, assign, distribute, host, or
      otherwise commercially exploit the Platform, whether in whole or in part,
      or any content displayed on the Platform (b) use the Platform in any
      manner that could damage, disable, overburden, or impair the Platform, or
      servers or networks connected to the Platform, or violate the regulations,
      policies or procedures of such networks; (c) obtain or attempt to obtain
      any materials or information through any means not intentionally made
      available or provided for through the Platform; (d) upload, transmit, or
      distribute to or through the Platform any computer viruses, worms, or any
      software intended to damage or alter a computer system or data; (e) access
      the Platform by any means other than through the interface that is
      provided by Level for use in accessing the Platform; (f) use or attempt to
      use any engine, software, tool, agent, or other device or mechanism
      (including without limitation browsers, spiders, robots, avatars or
      intelligent agents) to navigate or search the Platform; (g) attempt to
      decipher, decompile, disassemble, or reverse-engineer any of the software
      comprising or in any way making up a part of the Platform; or (h) you
      shall not access the Platform in order to build a similar or competitive
      website, product, or service.
      <br />
      Unless otherwise indicated, any future release, update, or other addition
      to functionality or content of the Platform shall be subject to these
      Terms of Service. All copyright and other proprietary notices on the
      Platform (or on any content displayed on the Platform) must be retained on
      all copies thereof.
      <br />
      If Level, in its sole discretion, believes that you may have engaged in
      any activities restricted by these Terms of Service or by law, we may take
      various actions to protect Level, other users, and other third parties
      from fees, fines, penalties, and any other liability. The actions we may
      take include the following:
      <br />
      (a) we may close, suspend, or limit your access to your Account or ability
      to use the Platform or related services; (b) we may update inaccurate
      information you provided us; (c) we may refuse to allow you to use the
      Platform or related services in the future; (d) we may report you to law
      enforcement authorities; (e) we may take legal action against you; and (f)
      we may hold you liable to Level for the amount of Level’s damages caused
      by your violation of these Terms of Service.
    </p>{" "}
    <h6>Level’s Intellectual Property Rights</h6>
    <p>
      Except for the Registration Information, all content included or available
      in connection with the Platform, including any and all materials,
      information, text, data, contents, names, trade names, trademarks, trade
      dress, service marks, layout, logos, designs, images, graphics,
      illustrations, artwork, icons, photographs, displays, sound, music, video,
      animation, organization, assembly, arrangement, interfaces, databases,
      technology, and all intellectual property of any kind whatsoever
      (collectively, the “Content”) and the selection and arrangement thereof is
      owned exclusively by Level or the licensors or suppliers of Level. All
      rights are hereby reserved. Neither these Terms of Service, nor your
      access to the Platform, transfers to you or any third party any rights,
      ownership or similar interests in or to such intellectual property rights,
      except for the limited access rights expressly set forth in section 8
      (Access to the Platform). There are no implied licences granted under
      these Terms of Service.
      <br />
      If you provide Level with any feedback or suggestions regarding the
      Platform (“Feedback”), you hereby transfer to Level all rights in such
      Feedback. You also agree that Level shall have the right to use and fully
      exploit such Feedback and related information in any manner it considers
      appropriate.
    </p>{" "}
    <h6>Disclaimer</h6>
    <p>
      Except as expressly set out in these Terms of Service, and to the maximum
      extent permitted by applicable law:
      <br />
      (a) the Platform, services, information, data, features, and all content
      provided by Level are offered and made available on an “as is” and “as
      available” basis; (b) Level makes no representations or warranties of any
      kind, whether express of implied, as to the accuracy, reliability,
      completeness or operation, or continued availability of the Platform, any
      content on the Platform and/or any related services; and (c) Level makes
      no representation, warranty, or guarantee, whether express or implied,
      that the content that may be available on or via the Platform or related
      services is free of bugs, defects or errors, or any viruses or other code
      or computer programming routines that contain contaminating or destructive
      properties or that are intended to damage, surreptitiously intercept or
      expropriate, any program, system or data. You acknowledge that the
      Platform has not been developed to meet your individual requirements, and
      that it is therefore your responsibility to ensure that the facilities and
      functions of the Platform meet your requirements.
      <br />
      Level is not responsible or liable for: (a) any delays in payment due or
      related to the intervention of any third party (including any delays
      caused by banks, failures or errors in payment or communications systems
      other than the Platform itself, or for regulatory purposes); (b) any
      delays in payment due or related to the occurrence of an administrative or
      technical error, or an event, which results in disruption (of a technical
      or system-related nature) to Level’s treasury or payment operations
      preventing Level from performing its payment obligations under the Terms
      of Service, or from communicating with you in accordance with these Terms
      of Service, and which is not caused by, and is beyond the control of,
      Level; and/or (c) your employer not having enough funds held with us to
      facilitate the payment of Salary and/or Early Salary Payments to your bank
      account.
      <br />
      The Platform is not intended to provide legal, tax, or financial advice.
      Level is not a financial planner, broker, or tax advisor. To the extent
      you use a Platform for banking or other financial services, the Platform
      is intended only to assist you in your financial organization and
      decision-making and is broad in scope. Before making any final decisions
      or implementing any financial strategy, you should consider obtaining
      additional information and advice from your accountant or other financial
      advisers who are fully aware of your individual circumstances.
      <br />
      Level does not assume any responsibility for the timeliness, accuracy,
      deletion, non-delivery, or failure to store Registration Information. Any
      information made available through the Platform will only reflect the
      information that was most recently uploaded to the Platform by your
      employer, and as such, may not reflect activity that occurred after your
      employer’s last update of the Platform or any pending transactions. Please
      contact your employer in case of inaccuracy of any information made
      available through the Platform, including information related to worked
      hours and Salary.
    </p>{" "}
    <h6>Limitation of Liability</h6>
    <p>
      Subject to the rest of this section (Limitation of Liability), if we fail
      to comply with these Terms of Service, we are responsible for loss or
      damage you suffer that is a foreseeable result of our breaching these
      Terms of Service or our failing to use reasonable care and skill, but we
      are not responsible for any loss or damage that is not foreseeable. Loss
      or damage is foreseeable if either it is obvious that it will happen or
      if, at the time these Terms of Service are entered into both we and you
      knew it might happen.
      <br />
      As noted above, we only make the Platform available for your domestic and
      private use. If you use the Platform for any commercial, business or
      re-sale purpose we will have no liability to you for any loss of profit,
      loss of business, business interruption, or loss of business opportunity.
      <br />
      We will not be responsible for any loss or damage incurred as a result of
      unauthorised access to your Account which is not within our reasonable
      control, including where you fail to comply with these Terms of Service.
      <br />
      Nothing in these Terms of Service shall limit or exclude our liability
      for:
      <br />
      (a) death or personal injury resulting from our negligence; (b) fraud or
      fraudulent misrepresentation; or (c) any other liability that cannot be
      excluded or limited by English law.
    </p>{" "}
    <h6>Term and Termination</h6>
    <p>
      Subject to this section (Term and Termination), these Terms of Service
      will remain in full force and effect while you use the Platform. Level, in
      its sole discretion, reserves the right to suspend or terminate your
      rights to use the Platform (including your Account), for any reason
      (including any use of the Platform in violation of these Terms of Service)
      and at any time, with or without notice to you.
      <br />
      Upon termination of your rights under these Terms of Service, your Account
      and right to access and use the Platform will terminate immediately. You
      understand that any termination of your Account may involve deletion of
      the Registration Information associated with your Account from our live
      databases.
      <br />
      Even after your rights under these Terms of Service are terminated, the
      following provisions of these Terms of Service will remain in effect: No
      Unlawful or Prohibited Use, Modification to Platform or Related Services,
      Level’s Intellectual Property Rights, Disclaimer, Limitation of Liability,
      Term and Termination, Governing Law & Forum for Disputes and
      Miscellaneous. Termination or suspension of any part of the services
      provided on or in relation to the Platform, or of these Terms of Service,
      will not affect relevant payment of Early Salary Payments requested and
      corresponding Net Salary (as applicable), in accordance with the terms of
      these Terms of Service.
      <br />
      Governing Law & Forum for Disputes These Terms of Service are governed by
      English law and we both agree to submit to the non‑exclusive jurisdiction
      of the English courts.{" "}
    </p>{" "}
    <h6>Miscellaneous</h6>
    <p>
      If any portion of these Terms of Service is deemed unlawful, void or
      unenforceable by any court of competent jurisdiction, these Terms of
      Service as a whole shall not be deemed unlawful, void or unenforceable,
      but only that portion of these Terms of Service that is unlawful, void or
      unenforceable shall be stricken from these Terms of Service.
      <br />
      You agree that if Level does not exercise or enforce any legal right or
      remedy which is contained in these Terms of Service (or which Level has
      the benefit of under any applicable law), this will not be taken to be a
      formal waiver of Level’s rights and that those rights or remedies will
      still be available to Level.
      <br />
      We may transfer our rights and obligations under these Terms of Service to
      another organisation – for example, this could include another member of
      our group of companies or someone who buys our business. We will always
      tell you in writing if this happens and we will ensure that the transfer
      will not affect your rights under these Terms of Service.
      <br />
      These Terms of Service are between you and us. No person, other than the
      employer, shall have any rights to enforce any of its terms.
      <br />
      Copyright © 2019 Level Finance Ltd. All rights reserved. All trademarks,
      logos and service marks (“Marks”) displayed on the Platform are our
      property or the property of other third parties. You are not permitted to
      use these Marks without our prior written consent or the consent of such
      third party which may own the Marks.
    </p>{" "}
    <h6> APPENDIX</h6>
    <p>Worked example of Early Salary Payments, deductions and Fees.</p>
    <p>
      You worked 120 hours at £10 per hour in this Payment Cycle. This means you
      have accrued pay due from your employer of £1200.
      <br />
      The Salary Day for work done in this Payment Cycle is the last Friday of
      the month – this means your employer stands to pay you £1200 on this
      Friday.
      <br />
      On the Wednesday in Week 3 you make a Request for £325 to be paid to you
      and the corresponding Early Salary Payment is made (incurring “Fee 1” –
      2%, £6.5); and Thursday in Week 4 you make a Request for £75 to be paid to
      you and the corresponding Early Salary Payment is made (incurring “Fee 2”
      – 2%, £1.5). Both these Early Salary Payments occur before the last Friday
      of the month when you are to be paid for work done in this Payment Cycle.
      <br />
      The combined sum of these two Early Salary Payments is 400 (i.e.
      £325+£75). Total fees incurred 8 (i.e. £6.5+£1.5).
      <br />
      The amount of the Net Salary you would receive on the last Friday of the
      month would be 1200-400-8= £792.
    </p>{" "}
  </div>
);
