import React, { Component } from "react";
import { Typography, Spin } from "antd";
import { confirmPhone } from "services/employeeService";
import * as auth from "services/authService";
const { Text } = Typography;

class ConfirmPhone extends Component {
  state = {
    confirmationDetails: {
      confirmationCode: "",
      userEmail: "",
      phoneNumber: ""
    },
    loading: false,
    error: ""
  };

  componentDidMount() {
    const { state } = this.props.location;
    let confirmationDetails = this.state.confirmationDetails;
    confirmationDetails.userEmail = state.userEmail;
    confirmationDetails.phoneNumber = state.phoneNumber;
    this.setState({ confirmationDetails });
  }

  handleChange = ({ currentTarget: input }) => {
    if (input.value.length > 6) return;

    const confirmationDetails = { ...this.state.confirmationDetails };
    confirmationDetails[input.name] = input.value;
    this.setState({ confirmationDetails });
  };

  confirmPhone = async () => {
    this.setState({ loading: true });
    const { data: result } = await confirmPhone(this.state.confirmationDetails);
    if (result.success) {
      auth.loginWithJWT(result.token);
      window.location = "/";
    } else {
      let confirmationDetails = this.state.confirmationDetails;
      confirmationDetails.confirmationCode = "";
      this.setState({
        loading: false,
        error: result.error,
        confirmationDetails
      });
    }
  };

  render() {
    const { loading } = this.state;
    const { phoneNumber } = this.state.confirmationDetails;
    return (
      <div className="phone-confirm-modal">
        <Spin spinning={loading}>
          <div className="phone-confirm-modal-wrapper">
            <div className="alignCenter ">
              {" "}
              <h1>Confirm your phone number</h1>
              <p className="darkGraySubtitle">
                To verify your identity, we just sent you a code to your number{" "}
                <b>{phoneNumber}</b>
              </p>
            </div>

            <input
              placeholder=". . . . . ."
              onChange={this.handleChange}
              value={this.state.confirmationDetails.confirmationCode}
              name="confirmationCode"
              autoFocus
            />

            {/* <Link to="/" className="mt-3 mb-3">
                  Send Again
                </Link> */}

            <div className="modal-action-call">
              <button className="liquid-primary" onClick={this.confirmPhone}>
                <p>Confirm</p>
              </button>
              {this.state.error ? (
                <Text type="danger">
                  {this.state.error}
                  <br />
                </Text>
              ) : null}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default ConfirmPhone;
