export function convertDate(dateCreated) {
  const date = new Date(dateCreated);
  if (date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (day % 10 === 1) {
      day = day + "st";
    } else if (day % 10 === 2) {
      day = day + "nd";
    } else if (day % 10 === 3) {
      day = day + "rd";
    } else {
      day = day + "th";
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  }
  return "";
}

export function convertDateToNumber(date) {
  if (!date) return "null";
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.getMonth();

  const result = year * 10000 + month * 100 + day;
  return result;
}

export function convertEndCycle(date) {
  //subtracting a day from the cycle end, for display purposes
  let endCycle = new Date(date);
  const oldDay = endCycle.getDate();
  endCycle.setDate(oldDay - 1);
  return convertDate(endCycle);
}
