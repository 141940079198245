import React from "react";
import { Link } from "react-router-dom";
import Form from "components/common/FormElements/Form";
import Joi from "joi-browser";
import { Spin, Typography } from "antd";
import * as auth from "services/authService";

class EmployeeLogin extends Form {
  state = {
    data: {
      email: "",
      password: ""
    },
    loading: false,
    errors: {},
    error: ""
  };

  schema = {
    email: Joi.string()
      .email()
      .required()
      .label("Email"),

    password: Joi.string()
      .min(6)
      .max(15)
      .label("Password")
  };

  submitLoginForm = async () => {
    this.startLoading();
    const { data } = this.state;
    try {
      const response = await auth.employeeLogin(data);
      this.stopLoading();
      if (!response.success) return this.setError("Failed to login");

      window.location = "/";
    } catch (error) {
      this.stopLoading();
      this.setError("Error logging in");
    }
  };

  render() {
    const { Text } = Typography;

    return (
      <Spin spinning={this.state.loading}>
        <div className="brand-bg-light">
          <div className="signup-form">
            <div className="signup-wrapper">
              <h3>Sign In</h3>

              <input
                placeholder="Email"
                onChange={this.handleChange}
                value={this.state.data.email}
                name="email"
              />
              <input
                type="password"
                placeholder="Password"
                onChange={this.handleChange}
                value={this.state.data.password}
                name="password"
              />
              {this.state.error ? (
                <Text type="danger">
                  {this.state.error}
                  <br />
                </Text>
              ) : null}
              <div className="alignCenter">
                <button
                  className="signup-form-button"
                  onClick={this.submitLoginForm}
                >
                  <p>Sign In</p>
                </button>
                <br />
                <Link to="/employee/signup">
                  Don't have an account? Sign up instead.
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default EmployeeLogin;
