import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavBar extends Component {
  state = {};

  render() {
    return (
      <nav className="liquid-nav-bar">
        <span className="liquid-navbar-brand">
          {/* <img
            className="centerPic mr-3"
            src={process.env.PUBLIC_URL + "/LevelLogo.svg"}
          /> */}
          <NavLink className="nav-link " to={this.props.homeLink}>
            <img
              alt="logo"
              className="navbar-logo"
              src={process.env.PUBLIC_URL + "/logo.svg"}
            />
          </NavLink>
          <span className="navbar-slogan">
            <p>Get paid on demand</p>
          </span>
        </span>
        <span className="liquid-navbar-brand-small">
          {" "}
          <NavLink className="nav-link " to={this.props.homeLink}>
            <img
              alt="small logo"
              className="navbar-logo-small"
              src={process.env.PUBLIC_URL + "/logo-small.svg"}
            />
          </NavLink>
        </span>
        {/* <div className="collapse navbar-collapse show">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/employee" exact>
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/employee/account">
                Account Settings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/employee/advances/history">
                Transaction History{" "}
              </NavLink>
            </li>
          </ul>
        </div> */}
        {this.props.user ? (
          <div className="nav-bar-end">
            {" "}
            <h6>
              {" "}
              <span role="img" aria-label="wave emoji" className="emoji-clap">
                👋
              </span>
              Hi, {this.props.user.name}!{" "}
              <span>
                {/* <button className="nav-link " to="/logout">
                  Logout
                </button> */}
              </span>
            </h6>
          </div>
        ) : (
          <div className="login-link">
            <NavLink className="nav-link " to="/employee/login">
              <h6>Log In </h6>
            </NavLink>
          </div>
        )}
      </nav>
    );
  }
}

export default NavBar;
