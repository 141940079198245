import React, { Component } from "react";
import { Modal } from "antd";

class InfoModal extends Component {
  state = {};
  render() {
    const { show, content, handleCancel } = this.props;
    return (
      <Modal
        visible={show}
        onCancel={handleCancel}
        footer={[]}
        className="info-modal"
        style={{ borderRadius: "18px !important" }}
      >
        {content}
      </Modal>
    );
  }
}

export default InfoModal;
