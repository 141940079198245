import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";

import NavBar from "./components/common/NavBar";
import Register from "./components/admin/Register";
import AccessDenied from "./components/auth/AccessDenied";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AdminProtected from "./components/auth/AdminProtected";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/auth/AdminLogin";
import EmployeeSignup from "./components/employee/RegisterAndLogin/EmployeeSignup";
import EmployeeLogin from "./components/employee/RegisterAndLogin/EmployeeLogin";
import UpdateXeroPaymentMethod from "./components/employee/AccountSettings/UpdateXeroPaymentMethod";
import EmployerTable from "./components/admin/CreateEmployer/EmployersTable";
import CreateEmployer from "./components/admin/CreateEmployer/CreateEmployer";
import EmployeeDashboard from "./components/employee/ControlPanel/Dashboard/EmployeeDashboard";
import ConfirmPhone from "./components/employee/RegisterAndLogin/ConfirmPhone";
import ConnectXero from "./components/admin/CreateEmployer/ConnectXero";
import EmployerAccount from "./components/admin/ManageEmployer/EmployerAccount";
import Logout from "./components/auth/Logout";
import AccountSettings from "./components/employee/AccountSettings/AccountSettings";
import TransactionHistory from "./components/employee/AccountSettings/TransactionHistory";
import ChangePassword from "./components/employee/AccountSettings/Password/ChangePassword";
import RequestPasswordChange from "./components/employee/AccountSettings/Password/RequestPasswordChange";
import ChangePasswordForm from "./components/employee/AccountSettings/Password/ChangePasswordForm";
import ViewEmployee from "./components/admin/ManageEmployee/ViewEmployee";
import InputSearch from "./components/common/FormElements/InputSearch";

import * as auth from "./services/authService";

import "./App.css";
import XeroIntegration from "./components/admin/ManageEmployer/XeroIntegration";

function App() {
  const user = auth.getCurrentUser();
  return (
    <React.Fragment>
      <NavBar
        className="navbarWhite"
        title="Level New"
        user={user}
        homeLink={user ? (user.type === 1 ? "/" : "/employee") : "/"}
      />
      <Layout className=" main">
        <Switch>
          {/* Public Routes */}
          <Route path="/access-denied" component={AccessDenied} />
          <Route path="/register" component={Register} />
          <Route path="/logout" component={Logout} />
          {/* Employee routes */}

          <Route
            path="/employee/signup/select/employer"
            component={InputSearch}
          />
          <Route path="/employee/signup" component={EmployeeSignup} />
          <Route path="/employee/phone/confirm" component={ConfirmPhone} />
          <Route path="/employee/login" component={EmployeeLogin} />
          <Route
            path="/employee/password/change/request"
            component={RequestPasswordChange}
          />
          <Route path="/employee/password/change" component={ChangePassword} />

          <Route
            path="/employee/change-password"
            component={ChangePasswordForm}
          />
          <ProtectedRoute
            path="/employee/account"
            render={props => <AccountSettings {...props} />}
          />

          <ProtectedRoute
            path="/employee/advances/history"
            render={props => <TransactionHistory {...props} />}
          />
          <ProtectedRoute
            path="/employee/xero"
            render={props => <UpdateXeroPaymentMethod {...props} />}
          />
          <ProtectedRoute
            path="/"
            render={props => <EmployeeDashboard {...props} />}
            exact
          />
          {/* Employer Routes */}
          <Route path="/employer/xero" component={ConnectXero} />
          {/* Admin routes */}
          <Route path="/admin/login" component={AdminLogin} />

          <AdminProtected
            path="/admin/employee/:employee"
            render={props => <ViewEmployee {...props} />}
          />
          <AdminProtected
            path="/admin/employer/create"
            render={props => <CreateEmployer {...props} />}
          />
          <AdminProtected
            path="/admin/employers"
            render={props => <EmployerTable {...props} />}
          />
          <AdminProtected
            path="/admin/employer/:employer"
            render={props => <EmployerAccount {...props} />}
          />
          <AdminProtected
            path="/admin/account"
            render={props => <AdminDashboard />}
          />
          <Route path="/integrate/xero" component={XeroIntegration} />
        </Switch>
      </Layout>
    </React.Fragment>
  );
}

export default App;
