import React, { Component } from "react";
import { Modal, Spin, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
const { Text } = Typography;

class WithdrawModal extends Component {
  state = {};
  displayAmount = amount => {
    if (amount === undefined || amount < 0) {
      return (0).toFixed(2);
    }
    return amount.toFixed(2);
  };
  render() {
    const {
      beneficiaryAccountDetails,
      showWithdrawModal,
      spinning,
      handleCancel,
      handleOk,
      totalAvailable,
      handleAmountChange,
      withdrawalValue,
      error
    } = this.props;

    return (
      <Modal
        className="popup-modal"
        visible={showWithdrawModal}
        onCancel={handleCancel}
        footer={null}
      >
        {" "}
        <Spin spinning={spinning}>
          <div className="modal-wrapper">
            <div className="modal-header">
              {" "}
              <h4> Withdrawal </h4>
            </div>
            <div className="modal-subheader">
              <p>
                Available:<b className="ml-2">£{totalAvailable.toFixed(2)}</b>
              </p>
            </div>
            <div className="modal-body">
              <div className="modal-body-wrapper">
                <h1>How much do you need?</h1>
                <input
                  placeholder="£0.00"
                  type="text"
                  onChange={handleAmountChange}
                  value={withdrawalValue}
                />
                <Divider
                  style={{
                    background: "#b8c2c7",
                    marginTop: "4px",
                    marginBottom: "4px",
                    height: "1px"
                  }}
                />
                {withdrawalValue > 0 ? (
                  <h2>
                    Sum of <b>£{withdrawalValue.toFixed(2)} </b>and
                    <b>
                      {" "}
                      £{parseFloat(withdrawalValue * 0.02).toFixed(2)}
                    </b>{" "}
                    commission will be deducted from your paycheck.
                  </h2>
                ) : (
                  <h2>Commission of 2% will be applied on the transaction</h2>
                )}
              </div>
            </div>{" "}
            <div className="modal-body">
              <div className="modal-body-wrapper">
                <div className="modal-body-header">
                  <h1>Send to</h1>
                  <span className="edit-bank-acc">
                    <Link to="/employee/account">
                      <p>edit</p>
                    </Link>
                  </span>
                </div>
                <h2>
                  {/* <b>{beneficiaryAccountDetails.holdersName}</b> */}
                  <b>Holder's Name</b>
                </h2>
                <h2>
                  account number: <b>{beneficiaryAccountDetails.accNumber}</b>
                </h2>
                <h2>
                  {" "}
                  sort code: <b>{beneficiaryAccountDetails.sortCode}</b>
                </h2>
              </div>
            </div>
            {error ? (
              <Text className="mb-1" type="danger">
                {error}
                <br />
              </Text>
            ) : null}
            {withdrawalValue === -1 && (
              <div className="modal-body">
                <div className="modal-body-wrapper">
                  <p className="smallGraySubtitle">Fee</p>
                  <h5 className="darkGraySubtitle mt-1">
                    <b>£{parseFloat(withdrawalValue * 0.02).toFixed(2)}</b>
                  </h5>
                  <p className="smallGray">
                    Withdrawn amount and transaction fee will be deducted from
                    your paycheck.
                  </p>
                </div>
              </div>
            )}
            <button className="liquid-primary" onClick={handleOk}>
              <div className="body">
                {" "}
                <p>Cash Out</p>
              </div>
            </button>
            <div className="modal-footer" />
          </div>

          {/* <p className="lightGraySubtitle">
            <span className="mr-1">Available:</span>
            <b>£{this.displayAmount(totalAvailable)}</b>
          </p>
          <div className="shadow rounded mb-2">
            <div className="p-3">
              <p className="darkGraySubtitle">How much do you need?</p>
              <span className="brandBlue poundSign ">
                <span className="mr-1"> £</span>
                <input
                  name="withdrawAmount"
                  className="mb-3 withdrawalAmount brandBlue"
                  onChange={handleAmountChange}
                  placeholder="0.00"
                  value={!withdrawalValue ? 0 : withdrawalValue}
                />
              </span>
            </div>
          </div>
          {withdrawalValue > 0 && (
            <div className="shadow rounded mb-2">
              <div className="p-3">
                <p className="smallGraySubtitle">Fee</p>
                <h5 className="darkGraySubtitle mt-1">
                  <b>£{parseFloat(withdrawalValue * 0.02).toFixed(2)}</b>
                </h5>
                <p className="smallGray">
                  Withdrawn amount and transaction fee will be deducted from
                  your paycheck.
                </p>
              </div>
            </div>
          )}

          <div className="alignCenter">
            {error ? (
              <Text type="danger">
                {error}
                <br />
              </Text>
            ) : null}
            <Button
              key="submit"
              className="wideBtn blueButton"
              size="large"
              onClick={handleOk}
            >
              Cash Out
            </Button>
          </div> */}
        </Spin>
      </Modal>
    );
  }
}

export default WithdrawModal;
