import React, { Component } from "react";
import { Typography } from "antd";
const { Text } = Typography;

export default class BasicInput extends Component {
  render() {
    const { name, label, error, placeholder, maxchars, ...rest } = this.props;
    console.log("ERROR", error);
    return (
      <div className="form-group">
        <input
          {...rest}
          className="signup-input"
          name={name}
          placeholder={placeholder}
          id={name}
          maxLength={maxchars}
        />

        {error && (
          <Text type="danger">
            {error}
            <br />
          </Text>
        )}
      </div>
    );
  }
}
