import React, { Component } from "react";
import { Row, Col, Tag } from "antd";
import { Link } from "react-router-dom";
import { convertDate } from "services/dateService";

class EmployeeTable extends Component {
  state = {};

  render() {
    const { employees } = this.props;
    return (
      <div>
        List all employees for the employer
        <Row>
          <Col span={2}>
            <Row> Full Name </Row>
          </Col>
          <Col span={4}>
            <Row> Phone number</Row>
          </Col>
          <Col span={3}>
            <p>EB Enduser</p>
          </Col>
          <Col span={3}>
            <p>RB Ledger</p>
          </Col>
          <Col span={3}>
            <p>Timesheet</p>
          </Col>
          <Col span={3}>
            <p>Pyroll</p>
          </Col>
          <Col span={3}>
            <p>Registered</p>
          </Col>
        </Row>
        {employees.map((emp, count) => (
          <Row key={count}>
            <Col span={2}>
              <Row>
                {" "}
                <Link to={"/admin/employee/" + emp.id}>{emp.displayName}</Link>
              </Row>
            </Col>
            <Col span={4}>
              <Row> {emp.phone}</Row>
            </Col>
            <Col span={3}>
              {emp.rbEnduserID ? (
                <Tag color="green">Enduser</Tag>
              ) : (
                <Tag color="red">No Enduser</Tag>
              )}
            </Col>
            <Col span={3}>
              {emp.rbGBPLedgerId ? (
                <Tag color="green">Ledger</Tag>
              ) : (
                <Tag color="red">No Ledger</Tag>
              )}
            </Col>
            <Col span={3}>
              {emp.timesheetConnected ? (
                <Tag color="green">Deputy On</Tag>
              ) : (
                <Tag color="red">Deputy Off</Tag>
              )}
            </Col>
            <Col span={3}>
              {emp.payrollConnected ? (
                <Tag color="green">Payroll </Tag>
              ) : (
                <Tag color="red">Payroll Off</Tag>
              )}
            </Col>
            <Col span={3}>
              <p>{convertDate(emp.createdAt)}</p>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default EmployeeTable;
