import React, { Component } from "react";
import { Button } from "antd";
import { connectXeroPayroll } from "services/employeeService";
import { toast } from "react-toastify";

class UpdateXeroPaymentMethod extends Component {
  state = {};
  handleClick = async () => {
    const { data: result } = await connectXeroPayroll();
    if (!result.success) toast.error("error connecting to Xero");
  };
  render() {
    return (
      <div>
        UpdateXeroPaymentMethod{" "}
        <Button onClick={this.handleClick}>Check details</Button>
      </div>
    );
  }
}

export default UpdateXeroPaymentMethod;
