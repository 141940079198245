import React, { Component } from "react";
import { Spin, Layout, message } from "antd";
import MainScreen from "components/employee/ControlPanel/Dashboard/MainScreen";
import { getEmployeeEarningDetails } from "services/adminService";
import { getEmployeeViaId } from "services/employeeService";

class ViewEmployeeDashboard extends Component {
  state = {
    employee: { ledger: null, beneficiary: {}, enduser: {} },
    beneficiaryAccountDetails: {
      uk_account_number: "",
      uk_sort_code: "",
      holdersName: ""
    },
    totalEarned: 0,
    totalAvailable: 0,
    totalAdvanced: 0,
    totalPending: 0,
    totalInProcessing: 0,
    advances: [],
    allAdvances: [],
    cycleStart: new Date(),
    cycleEnd: new Date(),
    approvedAdvances: [],
    enduser: {},
    beneficiary: {},
    updatingBeneficiary: false,
    loading: false
  };

  async componentDidMount() {
    this.startLoading();
    try {
      const { employee } = this.props;
      await this.getTotalEarnedAndAvailable(employee);
      await this.getEmployeeDetails(employee);
      this.stopLoading();
    } catch (error) {
      this.stopLoading();
    }
  }

  getEmployeeDetails = async employee => {
    if (!employee) return;
    const { data: response } = await getEmployeeViaId({ id: employee });
    const {
      uk_sort_code: sortCode,
      uk_account_number: accNumber
    } = response.employee.beneficiary;

    const {
      name: holdersName
    } = response.employee.beneficiary.beneficiary_holder.person;
    const beneficiaryAccountDetails = { sortCode, accNumber, holdersName };
    this.setState({ employee: response.employee, beneficiaryAccountDetails });
  };

  getTotalEarnedAndAvailable = async employee => {
    console.log("employee ", employee);
    if (!employee) return;

    const { data: response } = await getEmployeeEarningDetails({
      employee_id: employee,
      test: "test"
    });
    console.log("Current earning details", response);
    if (!response.success)
      return message.error("Error, getting earning details");
    const {
      totalEarned,
      totalAvailable,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      records,
      cycleStart,
      cycleEnd
    } = response;
    this.setState({
      totalEarned,
      totalAvailable,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      allAdvances: records,
      cycleStart,
      cycleEnd
    });
  };

  checkEmployeeAcountReady = () => {
    const { isLedgerReady, payrollConnected } = this.state.employee;
    return isLedgerReady && payrollConnected;
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const {
      loading,
      totalEarned,
      totalAdvanced,
      totalPending,
      totalInProcessing,
      totalAvailable,
      allAdvances,
      approvedAdvances,
      cycleStart,
      cycleEnd,
      beneficiaryAccountDetails
    } = this.state;
    const { rbBeneficiaryId, employeeID } = this.props.employee;
    return (
      <Layout>
        <Spin spinning={loading}>
          <MainScreen
            beneficiary={rbBeneficiaryId}
            employeeID={employeeID}
            accountReady={this.checkEmployeeAcountReady()}
            refreshDashboard={this.refreshDashboard}
            totalEarned={totalEarned}
            totalAvailable={totalAvailable}
            totalWithdrawn={totalAdvanced}
            totalPending={totalPending}
            totalInProcessing={totalInProcessing}
            allAdvances={allAdvances}
            approvedAdvances={approvedAdvances}
            updatedAdvnacesList={this.updateAdvanceRecords}
            cycleStart={cycleStart}
            cycleEnd={cycleEnd}
            adminView={true}
            beneficiaryAccountDetails={beneficiaryAccountDetails}
          />
        </Spin>
      </Layout>
    );
  }
}

export default ViewEmployeeDashboard;
