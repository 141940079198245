import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert } from "antd";

class ScreenAlerts extends Component {
  state = {};
  render() {
    const { beneficiary, accountReady } = this.props;
    return (
      <React.Fragment>
        {" "}
        {!beneficiary && (
          <Alert
            className="mt-2"
            message={
              <span>
                To start withdrawing advances you need to{" "}
                <Link to="/employee/account">add UK bank account</Link>
              </span>
            }
            type="warning"
          />
        )}
        {!accountReady && (
          <Alert
            className="mt-2 mb-2"
            message={
              <span>
                Your account is being set up and should be ready to use shortly.
              </span>
            }
            type="warning"
          />
        )}
      </React.Fragment>
    );
  }
}

export default ScreenAlerts;
