import React from "react";
import { Input, Button, Col, Typography, Spin, Layout } from "antd";
import Form from "components/common/FormElements/Form";
import queryString from "query-string";
import { updatePassword } from "services/employeeService";

const { Text } = Typography;

class ChangePassword extends Form {
  state = {
    data: {
      newPassword: "",
      confirmNewPassword: "",
      updateCode: ""
    },
    error: "",
    loading: false
  };
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    console.log("query", query);
    let data = {
      newPassword: "",
      confirmNewPassword: "",
      updateCode: ""
    };
    data.updateCode = query.action;
    this.setState({ data });
  }

  passwordsMatch = () => {
    const { newPassword, confirmNewPassword } = this.state.data;
    return newPassword === confirmNewPassword;
  };

  submitForm = async () => {
    this.startLoading();
    const passwordsMatch = this.passwordsMatch();
    if (!passwordsMatch) {
      this.stopLoading();
      return this.setState({ error: "passwords don't match" });
    }
    const { data } = this.state;
    try {
      const { data: response } = await updatePassword(data);
      if (!response.success) {
        this.stopLoading();
        return this.setState({ error: "error updating password" });
      }
      this.setState({ success: "password updated", error: "" });
      this.stopLoading();
      setTimeout(() => {
        window.location = "/employee/login";
      }, 1000);
    } catch (error) {
      this.stopLoading();
      this.setState({ error: "error occured" });
    }
  };

  render() {
    const { newPassword, confirmNewPassword, updateCode } = this.state.data;
    return (
      <Layout className="blueBackground">
        <Spin spinning={this.state.loading}>
          <div className="container blueBackground minWidth">
            <div>
              <Col
                span={12}
                offset={6}
                className="shadow p-3 mt-5 mb-5 bg-white rounded"
              >
                <Col span={16} offset={4} className="mt-4 mb-5">
                  <h3>Update password</h3>
                  <Input
                    className="mb-3"
                    placeholder="Action Code"
                    onChange={this.handleChange}
                    value={updateCode}
                    name="updateCode"
                  />
                  <Input.Password
                    className="mb-3"
                    placeholder="New password"
                    onChange={this.handleChange}
                    value={newPassword}
                    name="newPassword"
                  />
                  <Input.Password
                    className="mb-3"
                    placeholder="Confirm password"
                    onChange={this.handleChange}
                    value={confirmNewPassword}
                    name="confirmNewPassword"
                  />
                  {this.state.error ? (
                    <Text type="danger">
                      {this.state.error}
                      <br />
                    </Text>
                  ) : null}
                  <div className="alignCenter">
                    <Button
                      className="wideBtn roundedBtn blueButton"
                      size="large"
                      onClick={this.submitForm}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Col>
            </div>
          </div>
        </Spin>
      </Layout>
    );
  }
}

export default ChangePassword;
