import React from "react";
import Form from "components/common/FormElements/Form";
import { requestPasswordChange } from "services/employeeService";
import { message } from "antd";
import { Input, Button, Col, Spin, Layout } from "antd";
import { toast } from "react-toastify";

class RequestPasswordChange extends Form {
  state = { data: { email: "" }, loading: false };
  submitForm = async () => {
    const { data } = this.state;
    try {
      const { data: response } = await requestPasswordChange(data);
      if (!response.success) toast.error("error requesting password change");
      message.success("Password change link was sent to your email");
    } catch (error) {
      message.success("Password change link was sent to your email");
    }
  };
  render() {
    const { email } = this.state.data;
    return (
      <Layout className="blueBackground">
        <Spin spinning={this.state.loading}>
          <div className="container blueBackground minWidth">
            <div>
              <Col
                span={12}
                offset={6}
                className="shadow p-3 mt-5 mb-5 bg-white rounded"
              >
                <Col span={16} offset={4} className="mt-4 mb-5">
                  <h3>Password recovery</h3>
                  <Input
                    className="mb-3"
                    placeholder="Enter your email"
                    onChange={this.handleChange}
                    value={email}
                    name="email"
                  />

                  <div className="alignCenter">
                    <Button
                      className="wideBtn roundedBtn blueButton"
                      size="large"
                      onClick={this.submitForm}
                    >
                      Request password change
                    </Button>
                  </div>
                </Col>
              </Col>
            </div>
          </div>
        </Spin>
      </Layout>
    );
  }
}

export default RequestPasswordChange;
