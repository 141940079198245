import React from "react";
import SelectItem from "components/common/FormElements/SelectItem";

export default function DropDownList({ items, onSelect, notFound }) {
  console.log("Items", items);
  console.log("Not found", notFound);
  return (
    <div
      style={{ zIindex: 1000, position: "absolute" }}
      className="search-dropdown"
    >
      {notFound ? (
        <div>No matching results</div>
      ) : (
        items.map(item => (
          <SelectItem key={item.name} item={item} onSelect={onSelect} />
        ))
      )}
    </div>
  );
}
